<div id="main-div" *transloco="let t">
	<div id="sh-message-center-nav-logo-div">
		<button id="sh-message-center-nav-logo-button" [routerLink]="navigateTo" mat-button>
			<img id="sh-message-center-nav-logo-img" src="../assets/images/shure_logo_icon.svg" />
			<span id="sh-message-center-nav-logo-span">{{
				t('cloud.shared.message-center-side-nav.shure-cloud')
			}}</span>
		</button>
	</div>
	<mat-divider></mat-divider>
	<div id="message-center-nav-div">
		<span id="message-center-nav-title-span">{{ t('cloud.shared.message-center-side-nav.title') }}</span>
	</div>
	<mat-nav-list>
		<mat-list-item
			[routerLink]="['notifications-list']"
			[routerLinkActiveOptions]="{ exact: true }"
			routerLinkActive="mat-menu-item-active"
			(click)="setActive(messageCenterNotificationsListType.INBOX)"
		>
			<prism-icon icon="mail" id="message-center-nav-inbox-icon"></prism-icon>
			<span class="mat-list-text" id="message-center-nav-inbox-span">{{
				t('cloud.shared.message-center-side-nav.inbox')
			}}</span>
		</mat-list-item>
		<mat-list-item
			[routerLink]="['notifications-list/trash']"
			routerLinkActive="mat-menu-item-active"
			(click)="setActive(messageCenterNotificationsListType.TRASH)"
		>
			<prism-icon icon="auto_delete" id="message-center-nav-trash-icon"></prism-icon>
			<span class="mat-list-text" id="message-center-nav-trash-span">{{
				t('cloud.shared.message-center-side-nav.trash')
			}}</span>
		</mat-list-item>
	</mat-nav-list>
</div>
