import { Injectable, signal } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { BehaviorSubject } from 'rxjs';

export enum MessageCenterNotificationsListType {
	INBOX = 'inbox',
	TRASH = 'trash'
}

export interface NotificationStoreData {
	id: string;
	body?: string;
	sender: {
		firstName: string;
		lastName: string;
		email: string;
	};
	organization?: {
		id?: string;
		name?: string;
	};
	sentAt?: string;
	status: string;
	readAt?: string;
	archivedAt?: string;
	trashedAt?: string;
	subject: string;
	isRead?: boolean;
	isDeleted?: boolean;
	notificationlisttype?: string;
}

export interface NotificationState {
	notifications: NotificationStoreData[];
}

const initialState: NotificationState = {
	notifications: []
};

@Injectable({ providedIn: 'root' }) // This makes it singleton
export class NotificationsStore extends ComponentStore<NotificationState> {
	public selectedRowData!: NotificationStoreData;
	public isGetNotificationsCalled = false;

	public notificationFromWS: BehaviorSubject<NotificationStoreData | null> =
		new BehaviorSubject<NotificationStoreData | null>(null);

	// SELECTORS
	public notifications$ = this.select(
		// Filter out trashed/archived notifications
		(state) => state.notifications.filter((single) => !single.archivedAt && !single.trashedAt)
	);

	public trashedNotifications$ = this.select(
		// Filter out non-trashed notifications
		(state) => state.notifications.filter((single) => single.trashedAt)
	);

	public unreadNotificationsCount$ = this.select(
		// Filter out read/trashed/archived notifications
		(state) =>
			state.notifications.filter((single) => !single.archivedAt && !single.trashedAt && !single.readAt).length
	);

	public trashedNotificationsCount$ = this.select(
		// Filter out non-trashed notifications
		(state) => state.notifications.filter((single) => single.trashedAt).length
	);

	// UPDATERS
	public setNotifications = this.updater((state, notifications: NotificationStoreData[]) => {
		return { ...state, notifications: notifications };
	});

	public prependOrPatchNotifications = this.updater(
		(state, notificationsToAdd: Array<Pick<NotificationStoreData, 'id'> & Partial<NotificationStoreData>>) => {
			// Index existing notifications by id for quick access
			const notificationsIndex = new Map(
				state.notifications.map((notification) => [notification.id, notification])
			);

			// Prepare an array to hold new notifications
			const newNotifications: NotificationStoreData[] = [];

			// Iterate over new notifications to update existing ones or collect new ones
			for (const notification of notificationsToAdd) {
				if (notificationsIndex.has(notification.id)) {
					// If the notification exists, merge it with the new data using Object.assign
					Object.assign(<NotificationStoreData>notificationsIndex.get(notification.id), notification);
				} else {
					// If the notification is new, add it to the array of truly new notifications
					newNotifications.push(<NotificationStoreData>notification);
				}
			}

			// Prepend new notifications to the beginning of the existing notifications array
			// The order of existing notifications remains unchanged
			const updatedNotifications = [...newNotifications, ...state.notifications].sort((a, b) => {
				const parseDate = (dateString?: string | null): number => {
					if (!dateString) return 0;
					const timestamp = new Date(dateString).getTime();
					return isNaN(timestamp) ? 0 : timestamp;
				};

				const dateA = parseDate(a.sentAt);
				const dateB = parseDate(b.sentAt);

				if (dateA === 0 && dateB === 0) return 0;
				if (dateA === 0) return 1;
				if (dateB === 0) return -1;

				return dateB - dateA; // recent notification first
			});

			return {
				...state,
				notifications: updatedNotifications
			};
		}
	);

	public deleteNotification = this.updater((state, notificationId: string) => {
		return {
			...state,
			...{
				notifications: state.notifications.filter(
					(notification: NotificationStoreData) => notification.id !== notificationId
				)
			}
		};
	});

	public deleteNotifications = this.updater(
		(state, notificationsToDelete: Array<Pick<NotificationStoreData, 'id'> & Partial<NotificationStoreData>>) => {
			// Index existing notifications by id for quick access
			const notificationsIndex = new Map(
				state.notifications.map((notification) => [notification.id, notification])
			);

			// Iterate over new notifications to update existing ones or collect new ones
			for (const notification of notificationsToDelete) {
				notificationsIndex.delete(notification.id);
			}

			return {
				...state,
				notifications: [...notificationsIndex.values()]
			};
		}
	);

	public deleteAllTrashedNotifications = this.updater((state) => {
		return {
			...state,
			notifications: state.notifications.filter((notification) => !notification.trashedAt)
		};
	});

	constructor() {
		super(initialState);
	}
	// Define a signal to store the active list item
	private activeItemSignal = signal<string | null>(MessageCenterNotificationsListType.INBOX);

	public getActiveItem(): string | null {
		return this.activeItemSignal();
	}

	public setActiveItem(item: string): void {
		this.activeItemSignal.set(item);
	}
}
