import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { mapOktaLanguageCode } from '@shure/cloud/shared/services/organizations-store-service';
import { AppLanguageListService, LanguagesService } from '@shure/shared/angular/utils/i18n';

import { CommonDialogsService } from '../dialogs';
import { CloseTextOption, SnackbarService } from '../html-snackbar';

@Component({
	selector: 'sh-language-selector',
	templateUrl: 'language-selector.component.html',
	styleUrls: ['./language-selector.component.scss'],
	standalone: false
})
export class LanguageSelectorComponent implements OnInit, OnDestroy, OnChanges {
	@Output() public languageChanged: EventEmitter<string> = new EventEmitter();
	@Input() public preferredCultureCode = '';
	@Input() public isDisabled = false;
	@Input() public required = true;
	@Input() public label = '';
	public languages: { id: string; label: string }[] = [];
	public languageSelectorForm!: FormGroup;
	private languageCultureCodes: string[] = [];
	protected destroy$: Subject<void> = new Subject<void>();

	constructor(
		private languageService: LanguagesService,
		public dialogService: CommonDialogsService,
		private translocoService: TranslocoService,
		private snackBarService: SnackbarService,
		private appLanguageListService: AppLanguageListService
	) {}

	public ngOnInit(): void {
		this.languageSelectorForm = new FormGroup({
			preferredLanguage: new FormControl(
				this.preferredCultureCode ?? '',
				this.required ? Validators.required : null
			)
		});
		if (this.isDisabled) {
			this.languageSelectorForm.get('preferredLanguage')?.disable();
		}
		this.languages = this.appLanguageListService.getLanguageList().map((l) => ({ id: l.id, label: l.name }));
		this.languageCultureCodes = Object.values(mapOktaLanguageCode);
	}

	public ngOnChanges(changes: SimpleChanges): void {
		// eslint-disable-next-line dot-notation
		if (changes['preferredCultureCode']) {
			this.updatePreferredLanguageForm();
		}
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	public get preferredLanguage(): FormControl {
		return <FormControl>this.languageSelectorForm?.get('preferredLanguage') ?? new FormControl('');
	}

	public setLocale(cultureCode: string): void {
		const dialogRef = this.dialogService.openConfirmDialog({
			title: this.translocoService.translate('cloud.shared.labels.preferred-language-was-changed'),
			body: this.translocoService.translate(
				'cloud.shared.labels.reload-the-application-to-use-the-new-language-setting'
			),
			confirmLabel: this.translocoService.translate('cloud.shared.labels.reload'),
			cancelLabel: this.translocoService.translate('cloud.shared.labels.cancel')
		});

		dialogRef.pipe(take(1), takeUntil(this.destroy$)).subscribe((result) => {
			result ? this.setLocaleAndEmit(cultureCode) : this.updatePreferredLanguageForm();
		});
	}

	public setLocaleAndEmit(cultureCode: string): void {
		if (this.languageCultureCodes.includes(cultureCode)) {
			this.languageChanged.emit(cultureCode);
		} else {
			this.snackBarService.open(
				this.translocoService.translate('cloud.shared.notification-preferences.unsupported-language-message'),
				CloseTextOption.Ok
			);
			this.updatePreferredLanguageForm();
		}
	}

	private updatePreferredLanguageForm(): void {
		if (!this.languageSelectorForm) {
			return;
		}
		const cultureCode: string = this.preferredCultureCode;
		this.preferredLanguage.setValue(cultureCode);
		this.languageSelectorForm.markAsPristine();
	}
}
