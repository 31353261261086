import { proxyCustomElement, HTMLElement, createEvent, forceUpdate, h } from '@stencil/core/internal/client';
import { a as addToOverlay, r as removeFromOverlay } from './overlay.js';
import { d as defineCustomElement$6 } from './prism-floating-dialog-base2.js';
import { d as defineCustomElement$5 } from './prism-icon2.js';
import { d as defineCustomElement$4 } from './prism-icon-button2.js';
import { d as defineCustomElement$3 } from './prism-tooltip2.js';
import { d as defineCustomElement$2 } from './prism-tooltip-text2.js';
const prismFloatingDialogCss = ":host{display:block;width:0;height:0}";
const PrismFloatingDialogStyle0 = prismFloatingDialogCss;
const PrismFloatingDialog$1 = /*@__PURE__*/proxyCustomElement(class PrismFloatingDialog extends HTMLElement {
  constructor() {
    super();
    this.__registerHost();
    this.__attachShadow();
    this.visibleChange = createEvent(this, "visibleChange", 7);
    this.prismDialogClose = createEvent(this, "prismDialogClose", 7);
    /**
     * Handler for when the base dialog element emits a closeDialog event, which means that the close-dialog button was
     * pressed and this component needs to disable visibility.
     */
    this.handleCloseDialog = () => {
      this.visible = false;
      this.prismDialogClose.emit(false);
    };
    this.visible = false;
  }
  watchVisible(visible) {
    this.visibleChange.emit(visible);
  }
  /**
   * Lifecycle method for after the component is initially loaded. If the dialog is set to visible, we have to force
   * another update to ensure that the base dialog element is moved to the overlay
   */
  componentDidLoad() {
    if (this.visible) {
      forceUpdate(this);
    }
  }
  render() {
    return h("prism-floating-dialog-base", {
      key: '5046961eb74d915cf7c1ac20dcd2897dde01d418',
      visible: this.visible,
      onCloseDialog: this.handleCloseDialog,
      ref: element => this.floatingDialogBase = element
    });
  }
  /**
   * Lifecycle method for when the component will update. Ensures that the styling is applied to the base dialog
   * element and that is moved to or removed from the overlay.
   */
  componentWillUpdate() {
    if (this.visible) {
      this.updateStyling();
      this.floatingDialogBase.append(...this.hostElement.childNodes);
      addToOverlay(this.floatingDialogBase);
    } else {
      removeFromOverlay(this.floatingDialogBase);
    }
  }
  /**
   * Callback for when the component is disconnected. If this is done while the dialog is still shown, it needs to be
   * removed from the overlay.
   */
  disconnectedCallback() {
    if (this.visible) {
      removeFromOverlay(this.floatingDialogBase);
    }
  }
  /**
   * Update the styling of the base dialog element by copying any styling applied to this element to that element.
   */
  updateStyling() {
    const computedStyle = getComputedStyle(this.hostElement, null);
    const cssVariableNames = ['min-inline-size', 'max-inline-size', 'min-block-size', 'max-block-size', 'body-padding', 'bar-color'];
    cssVariableNames.forEach(cssVariableName => {
      const propertyName = `--prism-floating-dialog-${cssVariableName}`;
      const propertyValue = computedStyle.getPropertyValue(propertyName);
      if (!propertyValue) {
        return;
      }
      this.floatingDialogBase.style.setProperty(propertyName, propertyValue);
    });
  }
  get hostElement() {
    return this;
  }
  static get watchers() {
    return {
      "visible": ["watchVisible"]
    };
  }
  static get style() {
    return PrismFloatingDialogStyle0;
  }
}, [1, "prism-floating-dialog", {
  "visible": [1028]
}, undefined, {
  "visible": ["watchVisible"]
}]);
function defineCustomElement$1() {
  if (typeof customElements === "undefined") {
    return;
  }
  const components = ["prism-floating-dialog", "prism-floating-dialog-base", "prism-icon", "prism-icon-button", "prism-tooltip", "prism-tooltip-text"];
  components.forEach(tagName => {
    switch (tagName) {
      case "prism-floating-dialog":
        if (!customElements.get(tagName)) {
          customElements.define(tagName, PrismFloatingDialog$1);
        }
        break;
      case "prism-floating-dialog-base":
        if (!customElements.get(tagName)) {
          defineCustomElement$6();
        }
        break;
      case "prism-icon":
        if (!customElements.get(tagName)) {
          defineCustomElement$5();
        }
        break;
      case "prism-icon-button":
        if (!customElements.get(tagName)) {
          defineCustomElement$4();
        }
        break;
      case "prism-tooltip":
        if (!customElements.get(tagName)) {
          defineCustomElement$3();
        }
        break;
      case "prism-tooltip-text":
        if (!customElements.get(tagName)) {
          defineCustomElement$2();
        }
        break;
    }
  });
}
const PrismFloatingDialog = PrismFloatingDialog$1;
const defineCustomElement = defineCustomElement$1;
export { PrismFloatingDialog, defineCustomElement };

