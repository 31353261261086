import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ModuleWithProviders, NgModule, isDevMode } from '@angular/core';
import { TranslocoModule, provideTransloco } from '@jsverse/transloco';

import { LanguageCode } from '@shure/shared/models';

import { AppLanguageListService } from './app-language-list.service';
import { DEFAULT_APP_LANGUAGES, LanguageDefinition } from './transloco-default.config';
import { TranslocoHttpLoader } from './transloco-http.loader';

@NgModule({
	exports: [TranslocoModule],
	imports: [TranslocoModule],
	providers: [provideHttpClient(withInterceptorsFromDi())]
})
export class TranslocoRootModule {
	/**
	 * Setup application configuration for transloco.
	 */
	public static forRoot(
		flatten?: boolean,
		languages?: LanguageDefinition[]
	): ModuleWithProviders<TranslocoRootModule> {
		// Language list fallback
		if (languages === undefined) {
			languages = DEFAULT_APP_LANGUAGES;
		}

		// Default language fallback
		let defaultLanguage = LanguageCode.English;
		if (!languages.some((l) => l.id === defaultLanguage)) {
			defaultLanguage = languages[0].id;
		}

		const appLanguageListService = new AppLanguageListService(languages);

		// Create Transloco module
		return {
			ngModule: TranslocoRootModule,
			providers: [
				provideTransloco({
					config: {
						defaultLang: defaultLanguage,
						fallbackLang: defaultLanguage,
						availableLangs: languages.map((l) => ({ id: l.id, label: l.name })),
						prodMode: !isDevMode(),
						reRenderOnLangChange: true,
						flatten: { aot: flatten !== undefined ? flatten : !isDevMode() },
						missingHandler: {
							logMissingKey: true,
							allowEmpty: false,
							useFallbackTranslation: true
						}
					},
					loader: TranslocoHttpLoader
				}),
				{
					provide: AppLanguageListService,
					useValue: appLanguageListService
				}
			]
		};
	}
}
