<form [formGroup]="languageSelectorForm" novalidate *transloco="let t">
	<mat-form-field subscriptSizing="dynamic">
		<mat-label id="sh-account-language-label">{{ label }}</mat-label>
		<mat-select
			[formControl]="preferredLanguage"
			(selectionChange)="setLocaleAndEmit(preferredLanguage.value)"
			id="sh-account-language-selected"
		>
			<mat-option *ngFor="let lang of languages" [value]="lang.id"> {{ lang.label }} </mat-option>
		</mat-select>
		<mat-error
			*ngIf="
				languageSelectorForm.controls['preferredLanguage'].errors &&
				languageSelectorForm.controls['preferredLanguage'].errors['required']
			"
			id="sh-preferred-language-required-text"
		>
			{{ t('cloud.shared.error-labels.preferred-language-is-required') }}
		</mat-error>
	</mat-form-field>
</form>
