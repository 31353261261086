/**
 * NOTE:
 * Also change list of all languages in: tools/scripts/transloco/utils.ts if you change this
 */
/**
 * Supported language codes
 */
export enum LanguageCode {
	Arabic = 'ar',
	ChineseSimple = 'zh-cn',
	ChineseTraditional = 'zh-tw',
	English = 'en',
	French = 'fr',
	German = 'de',
	Italian = 'it',
	Japanese = 'ja',
	Korean = 'ko',
	Portuguese = 'pt',
	Russian = 'ru',
	Spanish = 'es'
}

export function adjustLanguageForDialects(languageCode: string, availableLanguages: LanguageCode[]): LanguageCode {
	// E.g. zh-CN and zh-TW would get translated to zh-cn and zh-tw
	// this also checks if the languageCode pass in is a direct match.
	const exactMatch = availableLanguages.find((lang) => lang.toLowerCase() === languageCode.toLowerCase());
	if (exactMatch) return exactMatch;

	// next, check if the language Code is a dialect, but we only support the primary
	// E.g. en-US and en-GB would get translated to en
	// Or check for language code with upper case, we only support lower case
	const partialMatch = availableLanguages.find((lang) => languageCode.indexOf(`${lang}-`) !== -1);
	if (partialMatch) return partialMatch;

	// else, default to English as a last resort
	return LanguageCode.English;
}
