import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { AgGridModule, ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

import { DeviceStatus } from '@shure/cloud/shared/models/devices';
import { IdButtonComponent } from '@shure/shared/angular/ui/components/buttons/id-button';

import { DeviceImageModule } from '../../device-image';
import { DeviceStateBarComponent } from '../../device-state-bar';

@Component({
	selector: 'sh-endpoint-name-renderer',
	templateUrl: './endpoint-name-renderer.component.html',
	styleUrls: ['./endpoint-name-renderer.component.scss'],
	standalone: true,
	imports: [CommonModule, AgGridModule, DeviceStateBarComponent, DeviceImageModule, MatIconModule, IdButtonComponent]
})
export class EndpointNameRendererComponent implements ICellRendererAngularComp {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public params!: any;
	public isFeatureEnable = false;
	public deviceStatus = DeviceStatus;

	constructor(private changeDetectorRef: ChangeDetectorRef) {}

	/**
	 * agInit - initiate the decommission cell param
	 * @param params - represent header parameter
	 */
	public agInit(params: ICellRendererParams): void {
		this.params = params;
	}

	/**
	 * refresh - initiate the decommission cell param
	 * @param params - represent header parameter
	 */
	public refresh(params: ICellRendererParams): boolean {
		this.params = params;
		this.changeDetectorRef.detectChanges();
		return true;
	}

	/**
	 * Extracts the channel count from a channel descriptor.
	 *
	 * @param channel - The channel descriptor, which can be a specific count like "8-channel" or "unassigned".
	 * @returns The numerical part of the channel descriptor if assigned, otherwise returns 'unassigned'.
	 */
	public getChannelCount(channel: string): string {
		if (!channel) {
			return '';
		}
		return channel !== 'unassigned' && typeof channel === 'string' ? channel.split('-')[0] : channel;
	}

	public setIdentify(deviceId: string, isIdentifying: boolean): void {
		this.params.context.updateEndPointNameInParent(deviceId, !isIdentifying);
	}
}
