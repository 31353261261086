<div *ngIf="params.data" id="sh-endpoint-name-renderer-cell-div">
	<span id="sh-endpoint-name-renderer-container-span">
		<sh-device-state-bar
			*ngIf="!params.data.hideStateBar && params.enableActivateFeature"
			id="sh-endpoint-name-renderer-device-state"
			[deviceState]="params.data.deviceStatus"
		></sh-device-state-bar>
		<sh-device-image
			*ngIf="params.data?.isDeviceImage && params.enableActivateFeature"
			id="sh-endpoint-name-renderer-device-image"
			[deviceModel]="params.data.model"
			[channelCount]="getChannelCount(params.data.channel)"
		>
		</sh-device-image>
		<span id="sh-endpoint-name-renderer-text-span">{{ params.data.name }}</span>
	</span>
	<sh-id-button
		*ngIf="params.data.canIdentify"
		[disabled]="params.data.isDisabled || params.data.deviceStatus !== deviceStatus.Online"
		[isIdentifying]="params.data.isIdentifying"
		(toggleIdentify)="setIdentify(params.data.id, !!params.data.isIdentifying)"
	></sh-id-button>
</div>
<div *ngIf="!params.data && params.value" id="sh-endpoint-name-renderer-text-div">{{ params.value }}</div>
