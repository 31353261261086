import { proxyCustomElement, HTMLElement, createEvent, h } from '@stencil/core/internal/client';
const prismKnobCss = ":host{display:inline-block}.pwc-knob-container{display:inline-grid;vertical-align:middle;place-items:center;outline:none;cursor:pointer;--_diameter:48px}.pwc-knob-circle{width:var(--_diameter);aspect-ratio:1;border-radius:var(--prism-border-radius-global-circle);background-color:var(--prism-color-theme-neutral-pop);grid-area:1/1/-1/-1}.pwc-knob-pointer{place-self:start center;width:2px;background-color:var(--prism-color-global-black);height:10px;grid-area:1/1/-1/-1;border-radius:0 0 var(--prism-border-radius-global-normal) var(--prism-border-radius-global-normal)}.pwc-knob-overlay{width:var(--_diameter);aspect-ratio:1;border-radius:var(--prism-border-radius-global-circle);grid-area:1/1/-1/-1;pointer-events:none;z-index:1}.pwc-knob-container:hover .pwc-knob-overlay{background-color:var(--prism-color-theme-window-film-hover)}.pwc-knob-container:not(.pwc-disabled):focus-visible .pwc-knob-circle{outline:2px solid var(--prism-color-theme-accent-primary);outline-offset:2px}.pwc-knob-container:not(.pwc-disabled):focus-visible .pwc-knob-overlay{background-color:var(--prism-color-theme-window-film-focused)}.pwc-knob-container.pwc-dragging .pwc-knob-pointer{background-color:var(--prism-color-theme-accent-primary)}.pwc-knob-container.pwc-dragging .pwc-knob-overlay{background-color:var(--prism-color-theme-window-film-pressed)}.pwc-knob-container.pwc-disabled{user-select:none;pointer-events:none;cursor:default}.pwc-knob-container.pwc-disabled .pwc-knob-circle{background-color:var(--prism-color-theme-disabled)}.pwc-knob-container.pwc-disabled .pwc-knob-pointer{background-color:var(--prism-color-theme-disabled-contrast)}.pwc-knob-container.pwc-disabled .pwc-knob-overlay{background-color:var(--prism-color-theme-disabled-contrast)}";
const PrismKnobStyle0 = prismKnobCss;
const PrismKnob$1 = /*@__PURE__*/proxyCustomElement(class PrismKnob extends HTMLElement {
  constructor() {
    super();
    this.__registerHost();
    this.__attachShadow();
    this.valueChange = createEvent(this, "valueChange", 7);
    this.valueInput = createEvent(this, "valueInput", 7);
    this.internals = this.attachInternals();
    this.min = 0;
    this.max = 100;
    this.step = 1;
    this.value = undefined;
    this.disabled = undefined;
    this.snapValues = undefined;
    this.snapRange = 0.03;
    this.dragging = false;
  }
  watchValue() {
    this.angle = this.getAngle();
    this.internals.setFormValue(this.value.toString());
  }
  componentWillRender() {
    if (this.value === undefined) {
      this.value = this.min;
    }
    this.angle = this.getAngle();
  }
  componentDidRender() {
    const circleRect = this.circle.getBoundingClientRect();
    this.circumference = circleRect.width * Math.PI;
  }
  /** Sets a default form value */
  formAssociatedCallback() {
    this.internals.setFormValue(this.value.toString());
  }
  render() {
    return h("div", {
      key: 'd4f0e6fdf4d76b1ee69f1dd6e7fbb7af89df38bf',
      class: `pwc-knob-container
					${this.disabled ? ' pwc-disabled' : ''}
					${this.dragging ? ' pwc-dragging' : ''}`,
      role: "slider",
      "aria-label": "knob",
      tabindex: this.disabled ? '-1' : '0',
      "aria-valuemin": this.min,
      "aria-valuemax": this.max,
      "aria-valuenow": this.value,
      style: {
        rotate: `${this.angle}rad`
      },
      onPointerDown: e => this.handlePointerDown(e),
      onPointerMove: e => this.handlePointerMove(e),
      onTouchMove: e => this.handleTouchMove(e),
      onKeyDown: e => this.handleKeyDown(e),
      draggable: false
    }, h("div", {
      key: '1aeceed626de82e7a93f60caebd1fe02c6a59791',
      class: "pwc-knob-circle",
      ref: el => this.circle = el
    }), h("div", {
      key: '9c0db3e5e56319ba98e1e43adf073f279d19b68c',
      class: "pwc-knob-pointer"
    }), h("div", {
      key: 'fd6dcc14911c83f1357b49054491b54a7a7fa6ab',
      class: "pwc-knob-overlay"
    }));
  }
  /** Capture pointer, save the current value */
  handlePointerDown(event) {
    if (!(event.target instanceof Element)) {
      return;
    }
    document.addEventListener('pointerup', e => this.handlePointerUp(e), {
      once: true
    });
    const target = event.target;
    target.setPointerCapture(event.pointerId);
    this.intermediateValue = this.value;
    this.dragging = true;
    event.preventDefault();
  }
  /** Update the value as the knob is dragged, snap to specific values if applicable */
  handlePointerMove(event) {
    if (!(event.target instanceof Element)) {
      return;
    }
    if (event.target.hasPointerCapture(event.pointerId)) {
      this.intermediateValue = this.getScaledValue(event.movementX, event.movementY);
      let modifiedValue = this.intermediateValue;
      if (this.snapValues) {
        this.snapValues.forEach(value => {
          const rangeProportion = Math.abs(this.intermediateValue - value) / (this.max - this.min);
          if (rangeProportion < this.snapRange) {
            modifiedValue = value;
          }
        });
      }
      modifiedValue = Math.max(Math.min(modifiedValue, this.max), this.min);
      this.value = Math.round(modifiedValue / this.step) * this.step;
      this.valueInput.emit(this.value);
      event.preventDefault();
    }
  }
  handleTouchMove(event) {
    event.preventDefault();
  }
  /** Release pointer, emit current value */
  handlePointerUp(event) {
    if (!(event.target instanceof Element)) {
      return;
    }
    this.dragging = false;
    event.target.releasePointerCapture(event.pointerId);
    this.valueChange.emit(this.value);
    event.preventDefault();
  }
  /** Increment the knob value using the arrow keys */
  handleKeyDown(event) {
    if (event.key === 'ArrowRight' || event.key === 'ArrowUp') {
      this.incrementValue(event, true);
    } else if (event.key === 'ArrowLeft' || event.key === 'ArrowDown') {
      this.incrementValue(event, false);
    }
  }
  incrementValue(event, up) {
    event.preventDefault();
    if (up && this.value < this.max) {
      this.value += this.step;
    } else if (!up && this.value > this.min) {
      this.value -= this.step;
    }
    this.valueInput.emit(this.value);
    this.valueChange.emit(this.value);
  }
  getScaledValue(x, y) {
    const rangeProportion = (this.max - this.min) / (2 * this.circumference);
    const scaledX = x * rangeProportion;
    const scaledY = y * rangeProportion;
    return this.intermediateValue + scaledX - scaledY;
  }
  getAngle() {
    const angleMin = -Math.PI + Math.PI / 4;
    const angleMax = Math.PI - Math.PI / 4;
    const m = (angleMax - angleMin) / Math.abs(this.max - this.min);
    const b = angleMax - this.max * m;
    return this.value * m + b;
  }
  static get formAssociated() {
    return true;
  }
  static get watchers() {
    return {
      "value": ["watchValue"]
    };
  }
  static get style() {
    return PrismKnobStyle0;
  }
}, [65, "prism-knob", {
  "min": [2],
  "max": [2],
  "step": [2],
  "value": [1026],
  "disabled": [4],
  "snapValues": [16],
  "snapRange": [2, "snap-range"],
  "dragging": [32]
}, undefined, {
  "value": ["watchValue"]
}]);
function defineCustomElement$1() {
  if (typeof customElements === "undefined") {
    return;
  }
  const components = ["prism-knob"];
  components.forEach(tagName => {
    switch (tagName) {
      case "prism-knob":
        if (!customElements.get(tagName)) {
          customElements.define(tagName, PrismKnob$1);
        }
        break;
    }
  });
}
const PrismKnob = PrismKnob$1;
const defineCustomElement = defineCustomElement$1;
export { PrismKnob, defineCustomElement };

