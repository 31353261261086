import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';

import { NotificationsStore, MessageCenterNotificationsListType } from '@shure/cloud/shared/notifications-store';
import { PrismIconComponent } from '@shure/prism-angular-components';

@Component({
	selector: 'sh-message-center-side-nav',
	templateUrl: './message-center-side-nav.component.html',
	styleUrl: './message-center-side-nav.component.scss',
	standalone: true,
	imports: [
		CommonModule,
		FlexLayoutModule,
		MatButtonModule,
		MatDividerModule,
		MatListModule,
		RouterModule,
		MatIconModule,
		MatMenuModule,
		TranslocoModule,
		PrismIconComponent
	]
})
export class MessageCenterSideNavComponent {
	@Input() public navigateTo!: string;
	public showSideNavItem = false;
	protected readonly messageCenterNotificationsListType = MessageCenterNotificationsListType;
	constructor(public notificationService: NotificationsStore) {}

	public setActive(item: string): void {
		this.notificationService.setActiveItem(item); // Set the clicked item as active
	}
}
