export const compareStrings = (a?: string | null, b?: string | null): number => {
	return compareLocaleStrings(a, b);
};

/**
 * Sorts comparison strings using built-in localeCompare
 * Returns 1 if a > b
 * Returns -1 if a < b
 * Returns 0 if a == b
 */
export function compareLocaleStrings(valueA?: string | null, valueB?: string | null): number {
	valueA = valueA ?? '';
	valueB = valueB ?? '';

	// using the localeCompare sort functionality for the time being to provide potential future i18n support
	return valueA.localeCompare(valueB, undefined, { numeric: true, sensitivity: 'base' });
}
