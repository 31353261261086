import * as i0 from '@angular/core';
import { InjectionToken, inject, Injector, Injectable, Directive, HostListener, ChangeDetectorRef, ElementRef, NgZone, Component, ChangeDetectionStrategy } from '@angular/core';
import { Subject as Subject$1, takeUntil, fromEvent } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { __decorate, __metadata } from 'tslib';
import { defineCustomElement as defineCustomElement$1 } from '@shure/prism-web-components/dist/components/prism-accordion.js';
import { defineCustomElement as defineCustomElement$2 } from '@shure/prism-web-components/dist/components/prism-accordion-panel.js';
import { defineCustomElement as defineCustomElement$3 } from '@shure/prism-web-components/dist/components/prism-badge.js';
import { defineCustomElement as defineCustomElement$4 } from '@shure/prism-web-components/dist/components/prism-battery.js';
import { defineCustomElement as defineCustomElement$5 } from '@shure/prism-web-components/dist/components/prism-battery-bar.js';
import { defineCustomElement as defineCustomElement$6 } from '@shure/prism-web-components/dist/components/prism-battery-gauge.js';
import { defineCustomElement as defineCustomElement$7 } from '@shure/prism-web-components/dist/components/prism-battery-indicator.js';
import { defineCustomElement as defineCustomElement$8 } from '@shure/prism-web-components/dist/components/prism-beta-indicator.js';
import { defineCustomElement as defineCustomElement$9 } from '@shure/prism-web-components/dist/components/prism-breadcrumb.js';
import { defineCustomElement as defineCustomElement$a } from '@shure/prism-web-components/dist/components/prism-breadcrumb-item.js';
import { defineCustomElement as defineCustomElement$b } from '@shure/prism-web-components/dist/components/prism-button.js';
import { defineCustomElement as defineCustomElement$c } from '@shure/prism-web-components/dist/components/prism-button-group.js';
import { defineCustomElement as defineCustomElement$d } from '@shure/prism-web-components/dist/components/prism-callout.js';
import { defineCustomElement as defineCustomElement$e } from '@shure/prism-web-components/dist/components/prism-card.js';
import { defineCustomElement as defineCustomElement$f } from '@shure/prism-web-components/dist/components/prism-checkbox.js';
import { defineCustomElement as defineCustomElement$g } from '@shure/prism-web-components/dist/components/prism-chip.js';
import { defineCustomElement as defineCustomElement$h } from '@shure/prism-web-components/dist/components/prism-dialog.js';
import { defineCustomElement as defineCustomElement$i } from '@shure/prism-web-components/dist/components/prism-file-input.js';
import { defineCustomElement as defineCustomElement$j } from '@shure/prism-web-components/dist/components/prism-floating-dialog.js';
import { defineCustomElement as defineCustomElement$k } from '@shure/prism-web-components/dist/components/prism-floating-dialog-base.js';
import { defineCustomElement as defineCustomElement$l } from '@shure/prism-web-components/dist/components/prism-graduated-container.js';
import { defineCustomElement as defineCustomElement$m } from '@shure/prism-web-components/dist/components/prism-icon.js';
import { defineCustomElement as defineCustomElement$n } from '@shure/prism-web-components/dist/components/prism-icon-button.js';
import { defineCustomElement as defineCustomElement$o } from '@shure/prism-web-components/dist/components/prism-input.js';
import { defineCustomElement as defineCustomElement$p } from '@shure/prism-web-components/dist/components/prism-knob.js';
import { defineCustomElement as defineCustomElement$q } from '@shure/prism-web-components/dist/components/prism-label-value.js';
import { defineCustomElement as defineCustomElement$r } from '@shure/prism-web-components/dist/components/prism-layout.js';
import { defineCustomElement as defineCustomElement$s } from '@shure/prism-web-components/dist/components/prism-link-info.js';
import { defineCustomElement as defineCustomElement$t } from '@shure/prism-web-components/dist/components/prism-list.js';
import { defineCustomElement as defineCustomElement$u } from '@shure/prism-web-components/dist/components/prism-list-item.js';
import { defineCustomElement as defineCustomElement$v } from '@shure/prism-web-components/dist/components/prism-menu.js';
import { defineCustomElement as defineCustomElement$w } from '@shure/prism-web-components/dist/components/prism-menu-base.js';
import { defineCustomElement as defineCustomElement$x } from '@shure/prism-web-components/dist/components/prism-menu-item.js';
import { defineCustomElement as defineCustomElement$y } from '@shure/prism-web-components/dist/components/prism-meter.js';
import { defineCustomElement as defineCustomElement$z } from '@shure/prism-web-components/dist/components/prism-meter-controller.js';
import { defineCustomElement as defineCustomElement$A } from '@shure/prism-web-components/dist/components/prism-mute-button.js';
import { defineCustomElement as defineCustomElement$B } from '@shure/prism-web-components/dist/components/prism-nav.js';
import { defineCustomElement as defineCustomElement$C } from '@shure/prism-web-components/dist/components/prism-nav-button.js';
import { defineCustomElement as defineCustomElement$D } from '@shure/prism-web-components/dist/components/prism-nav-group.js';
import { defineCustomElement as defineCustomElement$E } from '@shure/prism-web-components/dist/components/prism-nav-toggle-button.js';
import { defineCustomElement as defineCustomElement$F } from '@shure/prism-web-components/dist/components/prism-number-input.js';
import { defineCustomElement as defineCustomElement$G } from '@shure/prism-web-components/dist/components/prism-org-switcher.js';
import { defineCustomElement as defineCustomElement$H } from '@shure/prism-web-components/dist/components/prism-profile-image.js';
import { defineCustomElement as defineCustomElement$I } from '@shure/prism-web-components/dist/components/prism-profile-image-resize.js';
import { defineCustomElement as defineCustomElement$J } from '@shure/prism-web-components/dist/components/prism-progress-dots.js';
import { defineCustomElement as defineCustomElement$K } from '@shure/prism-web-components/dist/components/prism-progress-indicator.js';
import { defineCustomElement as defineCustomElement$L } from '@shure/prism-web-components/dist/components/prism-radio-button.js';
import { defineCustomElement as defineCustomElement$M } from '@shure/prism-web-components/dist/components/prism-radio-group.js';
import { defineCustomElement as defineCustomElement$N } from '@shure/prism-web-components/dist/components/prism-rf-meter.js';
import { defineCustomElement as defineCustomElement$O } from '@shure/prism-web-components/dist/components/prism-scale.js';
import { defineCustomElement as defineCustomElement$P } from '@shure/prism-web-components/dist/components/prism-search-bar.js';
import { defineCustomElement as defineCustomElement$Q } from '@shure/prism-web-components/dist/components/prism-segmented-picker.js';
import { defineCustomElement as defineCustomElement$R } from '@shure/prism-web-components/dist/components/prism-select.js';
import { defineCustomElement as defineCustomElement$S } from '@shure/prism-web-components/dist/components/prism-side-panel.js';
import { defineCustomElement as defineCustomElement$T } from '@shure/prism-web-components/dist/components/prism-side-panel-layout.js';
import { defineCustomElement as defineCustomElement$U } from '@shure/prism-web-components/dist/components/prism-slider.js';
import { defineCustomElement as defineCustomElement$V } from '@shure/prism-web-components/dist/components/prism-snackbar.js';
import { defineCustomElement as defineCustomElement$W } from '@shure/prism-web-components/dist/components/prism-status-indicator.js';
import { defineCustomElement as defineCustomElement$X } from '@shure/prism-web-components/dist/components/prism-step.js';
import { defineCustomElement as defineCustomElement$Y } from '@shure/prism-web-components/dist/components/prism-stepper.js';
import { defineCustomElement as defineCustomElement$Z } from '@shure/prism-web-components/dist/components/prism-stepper-dialog.js';
import { defineCustomElement as defineCustomElement$_ } from '@shure/prism-web-components/dist/components/prism-switch.js';
import { defineCustomElement as defineCustomElement$$ } from '@shure/prism-web-components/dist/components/prism-tab.js';
import { defineCustomElement as defineCustomElement$10 } from '@shure/prism-web-components/dist/components/prism-tab-group.js';
import { defineCustomElement as defineCustomElement$11 } from '@shure/prism-web-components/dist/components/prism-toolbar.js';
import { defineCustomElement as defineCustomElement$12 } from '@shure/prism-web-components/dist/components/prism-tooltip.js';
import { defineCustomElement as defineCustomElement$13 } from '@shure/prism-web-components/dist/components/prism-tooltip-text.js';
import { defineCustomElement as defineCustomElement$14 } from '@shure/prism-web-components/dist/components/prism-transmitter-info.js';

/**
 * Configuration for opening a modal dialog with the PrismDialog service.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const _c0 = ["*"];
class PrismDialogConfig {
  constructor() {
    /** Data being injected into the child component. */
    this.data = null;
  }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Reference to a dialog opened via the PrismDialog service.
 */
class PrismDialogRef {
  constructor() {
    this.afterClosedSubject = new Subject();
    /** An observable that is notified when the dialog is finished closing. */
    this.afterClosed$ = this.afterClosedSubject.asObservable();
  }
  /**
   * Close the dialog.
   * @param dialogResult Optional result to return to the dialog opener.
   */
  close(dialogResult) {
    this.afterClosedSubject.next(dialogResult);
    this.afterClosedSubject.complete();
  }
}

/** Injection token that can be used to access the data that was passed in to a dialog. */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PRISM_DIALOG_DATA = new InjectionToken('PRISM_DIALOG_DATA');
/** Injection token that can be used to specify default dialog options. */
const PRISM_DIALOG_DEFAULT_OPTIONS = new InjectionToken('prism-dialog-default-options');
// Counter for unique dialog ids.
let uniqueId = 0;
/**
 * Service for opening prism dialog/floating dialog in an Angular application.
 */
class PrismDialogService {
  constructor(injector) {
    this.injector = injector;
    this._defaultOptions = inject(PRISM_DIALOG_DEFAULT_OPTIONS, {
      optional: true
    });
    this.destroy$ = new Subject$1();
  }
  openDialog(component, vcr, config) {
    const dialogRef = new PrismDialogRef();
    config = {
      ...(this._defaultOptions || new PrismDialogConfig()),
      ...config
    };
    config.id = config?.id || `prism-dialog-${uniqueId++}`;
    dialogRef.id = config.id;
    const injector = Injector.create({
      providers: [{
        provide: PRISM_DIALOG_DATA,
        useValue: config?.data
      }, {
        provide: PrismDialogRef,
        useValue: dialogRef
      }],
      parent: this.injector
    });
    const componentRef = vcr.createComponent(component, {
      injector: injector
    });
    // Listen for a closing event coming from the prism-dialog/prism-floating-dialog
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    componentRef.location.nativeElement.addEventListener('prismDialogClose', event => {
      dialogRef.close(event.detail);
    });
    dialogRef.afterClosed$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      componentRef.location.nativeElement.removeEventListener('prismDialogClose', dialogRef.close);
      this.suspendService();
      vcr.remove();
    });
    dialogRef.componentInstance = componentRef.instance;
    return dialogRef;
  }
  suspendService() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  static {
    this.ɵfac = function PrismDialogService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismDialogService)(i0.ɵɵinject(i0.Injector));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: PrismDialogService,
      factory: PrismDialogService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismDialogService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i0.Injector
  }], null);
})();
class ValueAccessor {
  constructor(el) {
    this.el = el;
    this.onChange = () => {};
    this.onTouched = () => {};
  }
  writeValue(value) {
    this.el.nativeElement.value = this.lastValue = value == null ? '' : value;
  }
  handleChangeEvent(value) {
    if (value !== this.lastValue) {
      this.lastValue = value;
      this.onChange(value);
    }
  }
  _handleBlurEvent() {
    this.onTouched();
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled) {
    this.el.nativeElement.disabled = isDisabled;
  }
  static {
    this.ɵfac = function ValueAccessor_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ValueAccessor)(i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: ValueAccessor,
      hostBindings: function ValueAccessor_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("focusout", function ValueAccessor_focusout_HostBindingHandler() {
            return ctx._handleBlurEvent();
          });
        }
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ValueAccessor, [{
    type: Directive,
    args: [{}]
  }], () => [{
    type: i0.ElementRef
  }], {
    _handleBlurEvent: [{
      type: HostListener,
      args: ['focusout']
    }]
  });
})();
class BooleanValueAccessor extends ValueAccessor {
  constructor(el) {
    super(el);
  }
  writeValue(value) {
    this.el.nativeElement.checked = this.lastValue = value == null ? false : value;
  }
  static {
    this.ɵfac = function BooleanValueAccessor_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BooleanValueAccessor)(i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: BooleanValueAccessor,
      selectors: [["prism-button"], ["prism-icon-button"], ["prism-checkbox"], ["prism-switch"], ["prism-dialog"], ["prism-floating-dialog"], ["prism-snackbar"], ["prism-stepper-dialog"]],
      hostBindings: function BooleanValueAccessor_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("valueChange", function BooleanValueAccessor_valueChange_HostBindingHandler($event) {
            return ctx.handleChangeEvent($event.target.checked);
          })("visibleChange", function BooleanValueAccessor_visibleChange_HostBindingHandler($event) {
            return ctx.handleChangeEvent($event.target.visible);
          });
        }
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([{
        provide: NG_VALUE_ACCESSOR,
        useExisting: BooleanValueAccessor,
        multi: true
      }]), i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BooleanValueAccessor, [{
    type: Directive,
    args: [{
      standalone: true,
      /* tslint:disable-next-line:directive-selector */
      selector: 'prism-button, prism-icon-button, prism-checkbox, prism-switch, prism-dialog, prism-floating-dialog, prism-snackbar, prism-stepper-dialog',
      host: {
        '(valueChange)': 'handleChangeEvent($event.target.checked)',
        '(visibleChange)': 'handleChangeEvent($event.target.visible)'
      },
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: BooleanValueAccessor,
        multi: true
      }]
    }]
  }], () => [{
    type: i0.ElementRef
  }], null);
})();
class NumericValueAccessor extends ValueAccessor {
  constructor(el) {
    super(el);
  }
  registerOnChange(fn) {
    super.registerOnChange(value => {
      fn(value === '' ? null : parseFloat(value));
    });
  }
  static {
    this.ɵfac = function NumericValueAccessor_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NumericValueAccessor)(i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: NumericValueAccessor,
      selectors: [["prism-number-input"], ["prism-slider"], ["prism-knob"]],
      hostBindings: function NumericValueAccessor_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("valueChange", function NumericValueAccessor_valueChange_HostBindingHandler($event) {
            return ctx.handleChangeEvent($event.target.value);
          });
        }
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([{
        provide: NG_VALUE_ACCESSOR,
        useExisting: NumericValueAccessor,
        multi: true
      }]), i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NumericValueAccessor, [{
    type: Directive,
    args: [{
      standalone: true,
      /* tslint:disable-next-line:directive-selector */
      selector: 'prism-number-input, prism-slider, prism-knob',
      host: {
        '(valueChange)': 'handleChangeEvent($event.target.value)'
      },
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: NumericValueAccessor,
        multi: true
      }]
    }]
  }], () => [{
    type: i0.ElementRef
  }], null);
})();

/* eslint-disable */
/* tslint:disable */
const proxyInputs = (Cmp, inputs) => {
  const Prototype = Cmp.prototype;
  inputs.forEach(item => {
    Object.defineProperty(Prototype, item, {
      get() {
        return this.el[item];
      },
      set(val) {
        this.z.runOutsideAngular(() => this.el[item] = val);
      },
      /**
       * In the event that proxyInputs is called
       * multiple times re-defining these inputs
       * will cause an error to be thrown. As a result
       * we set configurable: true to indicate these
       * properties can be changed.
       */
      configurable: true
    });
  });
};
const proxyMethods = (Cmp, methods) => {
  const Prototype = Cmp.prototype;
  methods.forEach(methodName => {
    Prototype[methodName] = function () {
      const args = arguments;
      return this.z.runOutsideAngular(() => this.el[methodName].apply(this.el, args));
    };
  });
};
const proxyOutputs = (instance, el, events) => {
  events.forEach(eventName => instance[eventName] = fromEvent(el, eventName));
};
const defineCustomElement = (tagName, customElement) => {
  if (customElement !== undefined && typeof customElements !== 'undefined' && !customElements.get(tagName)) {
    customElements.define(tagName, customElement);
  }
};
// tslint:disable-next-line: only-arrow-functions
function ProxyCmp(opts) {
  const decorator = function (cls) {
    const {
      defineCustomElementFn,
      inputs,
      methods
    } = opts;
    if (defineCustomElementFn !== undefined) {
      defineCustomElementFn();
    }
    if (inputs) {
      proxyInputs(cls, inputs);
    }
    if (methods) {
      proxyMethods(cls, methods);
    }
    return cls;
  };
  return decorator;
}
let PrismAccordionComponent = class PrismAccordionComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function PrismAccordionComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismAccordionComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismAccordionComponent,
      selectors: [["prism-accordion"]],
      inputs: {
        exclusive: "exclusive"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismAccordionComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismAccordionComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$1,
  inputs: ['exclusive']
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismAccordionComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismAccordionComponent, [{
    type: Component,
    args: [{
      selector: 'prism-accordion',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['exclusive'],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismAccordionPanelComponent = class PrismAccordionPanelComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['expandedChange']);
  }
  static {
    this.ɵfac = function PrismAccordionPanelComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismAccordionPanelComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismAccordionPanelComponent,
      selectors: [["prism-accordion-panel"]],
      inputs: {
        expanded: "expanded"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismAccordionPanelComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismAccordionPanelComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$2,
  inputs: ['expanded']
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismAccordionPanelComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismAccordionPanelComponent, [{
    type: Component,
    args: [{
      selector: 'prism-accordion-panel',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['expanded'],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismBadgeComponent = class PrismBadgeComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function PrismBadgeComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismBadgeComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismBadgeComponent,
      selectors: [["prism-badge"]],
      inputs: {
        disabled: "disabled",
        value: "value"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismBadgeComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismBadgeComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$3,
  inputs: ['disabled', 'value']
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismBadgeComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismBadgeComponent, [{
    type: Component,
    args: [{
      selector: 'prism-badge',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled', 'value'],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismBatteryComponent = class PrismBatteryComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function PrismBatteryComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismBatteryComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismBatteryComponent,
      selectors: [["prism-battery"]],
      inputs: {
        lowLevelEnd: "lowLevelEnd",
        mediumLevelEnd: "mediumLevelEnd",
        state: "state",
        value: "value"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismBatteryComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismBatteryComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$4,
  inputs: ['lowLevelEnd', 'mediumLevelEnd', 'state', 'value']
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismBatteryComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismBatteryComponent, [{
    type: Component,
    args: [{
      selector: 'prism-battery',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['lowLevelEnd', 'mediumLevelEnd', 'state', 'value'],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismBatteryBarComponent = class PrismBatteryBarComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function PrismBatteryBarComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismBatteryBarComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismBatteryBarComponent,
      selectors: [["prism-battery-bar"]],
      inputs: {
        lowLevelEnd: "lowLevelEnd",
        mediumLevelEnd: "mediumLevelEnd",
        state: "state",
        value: "value"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismBatteryBarComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismBatteryBarComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$5,
  inputs: ['lowLevelEnd', 'mediumLevelEnd', 'state', 'value']
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismBatteryBarComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismBatteryBarComponent, [{
    type: Component,
    args: [{
      selector: 'prism-battery-bar',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['lowLevelEnd', 'mediumLevelEnd', 'state', 'value'],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismBatteryGaugeComponent = class PrismBatteryGaugeComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function PrismBatteryGaugeComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismBatteryGaugeComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismBatteryGaugeComponent,
      selectors: [["prism-battery-gauge"]],
      inputs: {
        gaugeState: "gaugeState",
        lowLevelEnd: "lowLevelEnd",
        mediumLevelEnd: "mediumLevelEnd",
        radius: "radius",
        value: "value"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismBatteryGaugeComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismBatteryGaugeComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$6,
  inputs: ['gaugeState', 'lowLevelEnd', 'mediumLevelEnd', 'radius', 'value']
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismBatteryGaugeComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismBatteryGaugeComponent, [{
    type: Component,
    args: [{
      selector: 'prism-battery-gauge',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['gaugeState', 'lowLevelEnd', 'mediumLevelEnd', 'radius', 'value'],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismBatteryIndicatorComponent = class PrismBatteryIndicatorComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function PrismBatteryIndicatorComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismBatteryIndicatorComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismBatteryIndicatorComponent,
      selectors: [["prism-battery-indicator"]],
      inputs: {
        state: "state",
        value: "value"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismBatteryIndicatorComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismBatteryIndicatorComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$7,
  inputs: ['state', 'value']
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismBatteryIndicatorComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismBatteryIndicatorComponent, [{
    type: Component,
    args: [{
      selector: 'prism-battery-indicator',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['state', 'value'],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismBetaIndicatorComponent = class PrismBetaIndicatorComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function PrismBetaIndicatorComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismBetaIndicatorComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismBetaIndicatorComponent,
      selectors: [["prism-beta-indicator"]],
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismBetaIndicatorComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismBetaIndicatorComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$8
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismBetaIndicatorComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismBetaIndicatorComponent, [{
    type: Component,
    args: [{
      selector: 'prism-beta-indicator',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: [],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismBreadcrumbComponent = class PrismBreadcrumbComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function PrismBreadcrumbComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismBreadcrumbComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismBreadcrumbComponent,
      selectors: [["prism-breadcrumb"]],
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismBreadcrumbComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismBreadcrumbComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$9
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismBreadcrumbComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismBreadcrumbComponent, [{
    type: Component,
    args: [{
      selector: 'prism-breadcrumb',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: [],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismBreadcrumbItemComponent = class PrismBreadcrumbItemComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['itemSelected']);
  }
  static {
    this.ɵfac = function PrismBreadcrumbItemComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismBreadcrumbItemComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismBreadcrumbItemComponent,
      selectors: [["prism-breadcrumb-item"]],
      inputs: {
        disabled: "disabled",
        href: "href",
        value: "value"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismBreadcrumbItemComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismBreadcrumbItemComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$a,
  inputs: ['disabled', 'href', 'value']
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismBreadcrumbItemComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismBreadcrumbItemComponent, [{
    type: Component,
    args: [{
      selector: 'prism-breadcrumb-item',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled', 'href', 'value'],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismButtonComponent = class PrismButtonComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['valueChange']);
  }
  static {
    this.ɵfac = function PrismButtonComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismButtonComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismButtonComponent,
      selectors: [["prism-button"]],
      inputs: {
        checked: "checked",
        disabled: "disabled",
        mute: "mute",
        size: "size",
        swap: "swap",
        tooltipDisabled: "tooltipDisabled",
        tooltipPlacement: "tooltipPlacement",
        tooltipText: "tooltipText",
        variant: "variant"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismButtonComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismButtonComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$b,
  inputs: ['checked', 'disabled', 'mute', 'size', 'swap', 'tooltipDisabled', 'tooltipPlacement', 'tooltipText', 'variant']
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismButtonComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismButtonComponent, [{
    type: Component,
    args: [{
      selector: 'prism-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['checked', 'disabled', 'mute', 'size', 'swap', 'tooltipDisabled', 'tooltipPlacement', 'tooltipText', 'variant'],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismButtonGroupComponent = class PrismButtonGroupComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function PrismButtonGroupComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismButtonGroupComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismButtonGroupComponent,
      selectors: [["prism-button-group"]],
      inputs: {
        column: "column"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismButtonGroupComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismButtonGroupComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$c,
  inputs: ['column']
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismButtonGroupComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismButtonGroupComponent, [{
    type: Component,
    args: [{
      selector: 'prism-button-group',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['column'],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismCalloutComponent = class PrismCalloutComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function PrismCalloutComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismCalloutComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismCalloutComponent,
      selectors: [["prism-callout"]],
      inputs: {
        variant: "variant"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismCalloutComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismCalloutComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$d,
  inputs: ['variant']
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismCalloutComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismCalloutComponent, [{
    type: Component,
    args: [{
      selector: 'prism-callout',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['variant'],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismCardComponent = class PrismCardComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function PrismCardComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismCardComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismCardComponent,
      selectors: [["prism-card"]],
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismCardComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismCardComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$e
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismCardComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismCardComponent, [{
    type: Component,
    args: [{
      selector: 'prism-card',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: [],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismCheckboxComponent = class PrismCheckboxComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['valueChange']);
  }
  static {
    this.ɵfac = function PrismCheckboxComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismCheckboxComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismCheckboxComponent,
      selectors: [["prism-checkbox"]],
      inputs: {
        checked: "checked",
        disabled: "disabled",
        indeterminate: "indeterminate",
        value: "value"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismCheckboxComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismCheckboxComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$f,
  inputs: ['checked', 'disabled', 'indeterminate', 'value']
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismCheckboxComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismCheckboxComponent, [{
    type: Component,
    args: [{
      selector: 'prism-checkbox',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['checked', 'disabled', 'indeterminate', 'value'],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismChipComponent = class PrismChipComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['close']);
  }
  static {
    this.ɵfac = function PrismChipComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismChipComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismChipComponent,
      selectors: [["prism-chip"]],
      inputs: {
        closeTooltipDisabled: "closeTooltipDisabled",
        closeTooltipText: "closeTooltipText",
        disabled: "disabled",
        isInput: "isInput",
        selected: "selected"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismChipComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismChipComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$g,
  inputs: ['closeTooltipDisabled', 'closeTooltipText', 'disabled', 'isInput', 'selected']
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismChipComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismChipComponent, [{
    type: Component,
    args: [{
      selector: 'prism-chip',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['closeTooltipDisabled', 'closeTooltipText', 'disabled', 'isInput', 'selected'],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismDialogComponent = class PrismDialogComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['prismDialogClose', 'visibleChange']);
  }
  static {
    this.ɵfac = function PrismDialogComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismDialogComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismDialogComponent,
      selectors: [["prism-dialog"]],
      inputs: {
        cancelButton: "cancelButton",
        cancelButtonDisabled: "cancelButtonDisabled",
        closeOnEsc: "closeOnEsc",
        confirmButton: "confirmButton",
        confirmButtonDisabled: "confirmButtonDisabled",
        visible: "visible"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismDialogComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismDialogComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$h,
  inputs: ['cancelButton', 'cancelButtonDisabled', 'closeOnEsc', 'confirmButton', 'confirmButtonDisabled', 'visible']
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismDialogComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismDialogComponent, [{
    type: Component,
    args: [{
      selector: 'prism-dialog',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['cancelButton', 'cancelButtonDisabled', 'closeOnEsc', 'confirmButton', 'confirmButtonDisabled', 'visible'],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismFileInputComponent = class PrismFileInputComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['valueChange']);
  }
  static {
    this.ɵfac = function PrismFileInputComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismFileInputComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismFileInputComponent,
      selectors: [["prism-file-input"]],
      inputs: {
        accept: "accept",
        disabled: "disabled",
        multiple: "multiple",
        name: "name"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismFileInputComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismFileInputComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$i,
  inputs: ['accept', 'disabled', 'multiple', 'name']
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismFileInputComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismFileInputComponent, [{
    type: Component,
    args: [{
      selector: 'prism-file-input',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['accept', 'disabled', 'multiple', 'name'],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismFloatingDialogComponent = class PrismFloatingDialogComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['visibleChange', 'prismDialogClose']);
  }
  static {
    this.ɵfac = function PrismFloatingDialogComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismFloatingDialogComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismFloatingDialogComponent,
      selectors: [["prism-floating-dialog"]],
      inputs: {
        visible: "visible"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismFloatingDialogComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismFloatingDialogComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$j,
  inputs: ['visible']
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismFloatingDialogComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismFloatingDialogComponent, [{
    type: Component,
    args: [{
      selector: 'prism-floating-dialog',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['visible'],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismFloatingDialogBaseComponent = class PrismFloatingDialogBaseComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['closeDialog']);
  }
  static {
    this.ɵfac = function PrismFloatingDialogBaseComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismFloatingDialogBaseComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismFloatingDialogBaseComponent,
      selectors: [["prism-floating-dialog-base"]],
      inputs: {
        visible: "visible"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismFloatingDialogBaseComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismFloatingDialogBaseComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$k,
  inputs: ['visible']
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismFloatingDialogBaseComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismFloatingDialogBaseComponent, [{
    type: Component,
    args: [{
      selector: 'prism-floating-dialog-base',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['visible'],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismGraduatedContainerComponent = class PrismGraduatedContainerComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function PrismGraduatedContainerComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismGraduatedContainerComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismGraduatedContainerComponent,
      selectors: [["prism-graduated-container"]],
      inputs: {
        orientation: "orientation"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismGraduatedContainerComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismGraduatedContainerComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$l,
  inputs: ['orientation']
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismGraduatedContainerComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismGraduatedContainerComponent, [{
    type: Component,
    args: [{
      selector: 'prism-graduated-container',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['orientation'],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismIconComponent = class PrismIconComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function PrismIconComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismIconComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismIconComponent,
      selectors: [["prism-icon"]],
      inputs: {
        customIcon: "customIcon",
        icon: "icon",
        size: "size"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismIconComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismIconComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$m,
  inputs: ['customIcon', 'icon', 'size']
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismIconComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismIconComponent, [{
    type: Component,
    args: [{
      selector: 'prism-icon',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['customIcon', 'icon', 'size'],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismIconButtonComponent = class PrismIconButtonComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['valueChange']);
  }
  static {
    this.ɵfac = function PrismIconButtonComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismIconButtonComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismIconButtonComponent,
      selectors: [["prism-icon-button"]],
      inputs: {
        checked: "checked",
        customIcon: "customIcon",
        disabled: "disabled",
        icon: "icon",
        label: "label",
        size: "size",
        toggle: "toggle",
        tooltipDisabled: "tooltipDisabled",
        tooltipPlacement: "tooltipPlacement"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismIconButtonComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismIconButtonComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$n,
  inputs: ['checked', 'customIcon', 'disabled', 'icon', 'label', 'size', 'toggle', 'tooltipDisabled', 'tooltipPlacement']
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismIconButtonComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismIconButtonComponent, [{
    type: Component,
    args: [{
      selector: 'prism-icon-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['checked', 'customIcon', 'disabled', 'icon', 'label', 'size', 'toggle', 'tooltipDisabled', 'tooltipPlacement'],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismInputComponent = class PrismInputComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['valueInput', 'valueChange']);
  }
  static {
    this.ɵfac = function PrismInputComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismInputComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismInputComponent,
      selectors: [["prism-input"]],
      inputs: {
        aboveMaxLengthError: "aboveMaxLengthError",
        angularInput: "angularInput",
        autocapitalize: "autocapitalize",
        autocomplete: "autocomplete",
        autocorrect: "autocorrect",
        autofocus: "autofocus",
        belowMinLengthError: "belowMinLengthError",
        disabled: "disabled",
        form: "form",
        inputmode: "inputmode",
        instantValidation: "instantValidation",
        isMenuOpen: "isMenuOpen",
        isValid: "isValid",
        maxlength: "maxlength",
        minlength: "minlength",
        name: "name",
        pattern: "pattern",
        placeholder: "placeholder",
        readonly: "readonly",
        required: "required",
        requiredError: "requiredError",
        type: "type",
        validator: "validator",
        value: "value"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismInputComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismInputComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$o,
  inputs: ['aboveMaxLengthError', 'angularInput', 'autocapitalize', 'autocomplete', 'autocorrect', 'autofocus', 'belowMinLengthError', 'disabled', 'form', 'inputmode', 'instantValidation', 'isMenuOpen', 'isValid', 'maxlength', 'minlength', 'name', 'pattern', 'placeholder', 'readonly', 'required', 'requiredError', 'type', 'validator', 'value']
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismInputComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismInputComponent, [{
    type: Component,
    args: [{
      selector: 'prism-input',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['aboveMaxLengthError', 'angularInput', 'autocapitalize', 'autocomplete', 'autocorrect', 'autofocus', 'belowMinLengthError', 'disabled', 'form', 'inputmode', 'instantValidation', 'isMenuOpen', 'isValid', 'maxlength', 'minlength', 'name', 'pattern', 'placeholder', 'readonly', 'required', 'requiredError', 'type', 'validator', 'value'],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismKnobComponent = class PrismKnobComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['valueChange', 'valueInput']);
  }
  static {
    this.ɵfac = function PrismKnobComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismKnobComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismKnobComponent,
      selectors: [["prism-knob"]],
      inputs: {
        disabled: "disabled",
        max: "max",
        min: "min",
        snapRange: "snapRange",
        snapValues: "snapValues",
        step: "step",
        value: "value"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismKnobComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismKnobComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$p,
  inputs: ['disabled', 'max', 'min', 'snapRange', 'snapValues', 'step', 'value']
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismKnobComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismKnobComponent, [{
    type: Component,
    args: [{
      selector: 'prism-knob',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled', 'max', 'min', 'snapRange', 'snapValues', 'step', 'value'],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismLabelValueComponent = class PrismLabelValueComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function PrismLabelValueComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismLabelValueComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismLabelValueComponent,
      selectors: [["prism-label-value"]],
      inputs: {
        labelDisabled: "labelDisabled",
        valueDisabled: "valueDisabled"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismLabelValueComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismLabelValueComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$q,
  inputs: ['labelDisabled', 'valueDisabled']
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismLabelValueComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismLabelValueComponent, [{
    type: Component,
    args: [{
      selector: 'prism-label-value',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['labelDisabled', 'valueDisabled'],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismLayoutComponent = class PrismLayoutComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['pwcToggleNavButton']);
  }
  static {
    this.ɵfac = function PrismLayoutComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismLayoutComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismLayoutComponent,
      selectors: [["prism-layout"]],
      inputs: {
        navOpen: "navOpen"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismLayoutComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismLayoutComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$r,
  inputs: ['navOpen']
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismLayoutComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismLayoutComponent, [{
    type: Component,
    args: [{
      selector: 'prism-layout',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['navOpen'],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismLinkInfoComponent = class PrismLinkInfoComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function PrismLinkInfoComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismLinkInfoComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismLinkInfoComponent,
      selectors: [["prism-link-info"]],
      inputs: {
        linked: "linked"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismLinkInfoComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismLinkInfoComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$s,
  inputs: ['linked']
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismLinkInfoComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismLinkInfoComponent, [{
    type: Component,
    args: [{
      selector: 'prism-link-info',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['linked'],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismListComponent = class PrismListComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function PrismListComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismListComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismListComponent,
      selectors: [["prism-list"]],
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismListComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismListComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$t
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismListComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismListComponent, [{
    type: Component,
    args: [{
      selector: 'prism-list',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: [],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismListItemComponent = class PrismListItemComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function PrismListItemComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismListItemComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismListItemComponent,
      selectors: [["prism-list-item"]],
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismListItemComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismListItemComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$u
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismListItemComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismListItemComponent, [{
    type: Component,
    args: [{
      selector: 'prism-list-item',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: [],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismMenuComponent = class PrismMenuComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['valueChange', 'selectMenuValueChange', 'visibleChange']);
  }
  static {
    this.ɵfac = function PrismMenuComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismMenuComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismMenuComponent,
      selectors: [["prism-menu"]],
      inputs: {
        editableSelectMenu: "editableSelectMenu",
        placement: "placement",
        selectMenu: "selectMenu",
        submenu: "submenu",
        value: "value",
        visible: "visible"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismMenuComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismMenuComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$v,
  inputs: ['editableSelectMenu', 'placement', 'selectMenu', 'submenu', 'value', 'visible']
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismMenuComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismMenuComponent, [{
    type: Component,
    args: [{
      selector: 'prism-menu',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['editableSelectMenu', 'placement', 'selectMenu', 'submenu', 'value', 'visible'],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismMenuBaseComponent = class PrismMenuBaseComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function PrismMenuBaseComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismMenuBaseComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismMenuBaseComponent,
      selectors: [["prism-menu-base"]],
      inputs: {
        editableSelectMenu: "editableSelectMenu",
        parentMenu: "parentMenu",
        selectMenu: "selectMenu",
        submenu: "submenu",
        visible: "visible"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismMenuBaseComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismMenuBaseComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$w,
  inputs: ['editableSelectMenu', 'parentMenu', 'selectMenu', 'submenu', 'visible']
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismMenuBaseComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismMenuBaseComponent, [{
    type: Component,
    args: [{
      selector: 'prism-menu-base',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['editableSelectMenu', 'parentMenu', 'selectMenu', 'submenu', 'visible'],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismMenuItemComponent = class PrismMenuItemComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['itemSelected']);
  }
  static {
    this.ɵfac = function PrismMenuItemComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismMenuItemComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismMenuItemComponent,
      selectors: [["prism-menu-item"]],
      inputs: {
        active: "active",
        disabled: "disabled",
        selectMenuItem: "selectMenuItem",
        selected: "selected",
        submenu: "submenu",
        value: "value"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismMenuItemComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismMenuItemComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$x,
  inputs: ['active', 'disabled', 'selectMenuItem', 'selected', 'submenu', 'value']
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismMenuItemComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismMenuItemComponent, [{
    type: Component,
    args: [{
      selector: 'prism-menu-item',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['active', 'disabled', 'selectMenuItem', 'selected', 'submenu', 'value'],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismMeterComponent = class PrismMeterComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['componentResize']);
  }
  static {
    this.ɵfac = function PrismMeterComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismMeterComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismMeterComponent,
      selectors: [["prism-meter"]],
      inputs: {
        boundingRect: "boundingRect",
        controller: "controller",
        disabled: "disabled",
        intersectionRect: "intersectionRect",
        max: "max",
        min: "min",
        orientation: "orientation",
        peak: "peak",
        value: "value",
        visible: "visible"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismMeterComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismMeterComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$y,
  inputs: ['boundingRect', 'controller', 'disabled', 'intersectionRect', 'max', 'min', 'orientation', 'peak', 'value', 'visible'],
  methods: ['updateBoundingRect', 'tick']
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismMeterComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismMeterComponent, [{
    type: Component,
    args: [{
      selector: 'prism-meter',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['boundingRect', 'controller', 'disabled', 'intersectionRect', 'max', 'min', 'orientation', 'peak', 'value', 'visible'],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismMeterControllerComponent = class PrismMeterControllerComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function PrismMeterControllerComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismMeterControllerComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismMeterControllerComponent,
      selectors: [["prism-meter-controller"]],
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismMeterControllerComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismMeterControllerComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$z
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismMeterControllerComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismMeterControllerComponent, [{
    type: Component,
    args: [{
      selector: 'prism-meter-controller',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: [],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismMuteButtonComponent = class PrismMuteButtonComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['muteChange', 'linkChange']);
  }
  static {
    this.ɵfac = function PrismMuteButtonComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismMuteButtonComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismMuteButtonComponent,
      selectors: [["prism-mute-button"]],
      inputs: {
        disabled: "disabled",
        icon: "icon",
        link: "link",
        linkDisabled: "linkDisabled",
        linkTooltipDisabled: "linkTooltipDisabled",
        linkTooltipPlacement: "linkTooltipPlacement",
        linkTooltipText: "linkTooltipText",
        linked: "linked",
        muteText: "muteText",
        muteTooltipDisabled: "muteTooltipDisabled",
        muteTooltipPlacement: "muteTooltipPlacement",
        muteTooltipText: "muteTooltipText",
        muted: "muted",
        unmuteText: "unmuteText"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismMuteButtonComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismMuteButtonComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$A,
  inputs: ['disabled', 'icon', 'link', 'linkDisabled', 'linkTooltipDisabled', 'linkTooltipPlacement', 'linkTooltipText', 'linked', 'muteText', 'muteTooltipDisabled', 'muteTooltipPlacement', 'muteTooltipText', 'muted', 'unmuteText']
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismMuteButtonComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismMuteButtonComponent, [{
    type: Component,
    args: [{
      selector: 'prism-mute-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled', 'icon', 'link', 'linkDisabled', 'linkTooltipDisabled', 'linkTooltipPlacement', 'linkTooltipText', 'linked', 'muteText', 'muteTooltipDisabled', 'muteTooltipPlacement', 'muteTooltipText', 'muted', 'unmuteText'],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismNavComponent = class PrismNavComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function PrismNavComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismNavComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismNavComponent,
      selectors: [["prism-nav"]],
      inputs: {
        accent: "accent",
        hasSecondaryNav: "hasSecondaryNav",
        secondary: "secondary",
        smallScreen: "smallScreen"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismNavComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismNavComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$B,
  inputs: ['accent', 'hasSecondaryNav', 'secondary', 'smallScreen']
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismNavComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismNavComponent, [{
    type: Component,
    args: [{
      selector: 'prism-nav',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['accent', 'hasSecondaryNav', 'secondary', 'smallScreen'],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismNavButtonComponent = class PrismNavButtonComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['pwcNavButtonSelected']);
  }
  static {
    this.ɵfac = function PrismNavButtonComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismNavButtonComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismNavButtonComponent,
      selectors: [["prism-nav-button"]],
      inputs: {
        accent: "accent",
        buttonId: "buttonId",
        disabled: "disabled",
        expanded: "expanded",
        href: "href",
        isParent: "isParent",
        secondary: "secondary",
        selected: "selected",
        smallScreen: "smallScreen",
        visible: "visible"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismNavButtonComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismNavButtonComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$C,
  inputs: ['accent', 'buttonId', 'disabled', 'expanded', 'href', 'isParent', 'secondary', 'selected', 'smallScreen', 'visible']
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismNavButtonComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismNavButtonComponent, [{
    type: Component,
    args: [{
      selector: 'prism-nav-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['accent', 'buttonId', 'disabled', 'expanded', 'href', 'isParent', 'secondary', 'selected', 'smallScreen', 'visible'],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismNavGroupComponent = class PrismNavGroupComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function PrismNavGroupComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismNavGroupComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismNavGroupComponent,
      selectors: [["prism-nav-group"]],
      inputs: {
        level: "level",
        secondary: "secondary"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismNavGroupComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismNavGroupComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$D,
  inputs: ['level', 'secondary']
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismNavGroupComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismNavGroupComponent, [{
    type: Component,
    args: [{
      selector: 'prism-nav-group',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['level', 'secondary'],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismNavToggleButtonComponent = class PrismNavToggleButtonComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['pwcToggleNav']);
  }
  static {
    this.ɵfac = function PrismNavToggleButtonComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismNavToggleButtonComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismNavToggleButtonComponent,
      selectors: [["prism-nav-toggle-button"]],
      inputs: {
        disabled: "disabled",
        isOpen: "isOpen"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismNavToggleButtonComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismNavToggleButtonComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$E,
  inputs: ['disabled', 'isOpen']
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismNavToggleButtonComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismNavToggleButtonComponent, [{
    type: Component,
    args: [{
      selector: 'prism-nav-toggle-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled', 'isOpen'],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismNumberInputComponent = class PrismNumberInputComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['valueInput', 'valueChange']);
  }
  static {
    this.ɵfac = function PrismNumberInputComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismNumberInputComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismNumberInputComponent,
      selectors: [["prism-number-input"]],
      inputs: {
        aboveMaxError: "aboveMaxError",
        belowMinError: "belowMinError",
        blurAlias: "blurAlias",
        disabled: "disabled",
        displayValue: "displayValue",
        isValid: "isValid",
        max: "max",
        min: "min",
        nonNumericError: "nonNumericError",
        placeholder: "placeholder",
        required: "required",
        requiredError: "requiredError",
        stacked: "stacked",
        step: "step",
        unit: "unit",
        value: "value"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismNumberInputComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismNumberInputComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$F,
  inputs: ['aboveMaxError', 'belowMinError', 'blurAlias', 'disabled', 'displayValue', 'isValid', 'max', 'min', 'nonNumericError', 'placeholder', 'required', 'requiredError', 'stacked', 'step', 'unit', 'value']
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismNumberInputComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismNumberInputComponent, [{
    type: Component,
    args: [{
      selector: 'prism-number-input',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['aboveMaxError', 'belowMinError', 'blurAlias', 'disabled', 'displayValue', 'isValid', 'max', 'min', 'nonNumericError', 'placeholder', 'required', 'requiredError', 'stacked', 'step', 'unit', 'value'],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismOrgSwitcherComponent = class PrismOrgSwitcherComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function PrismOrgSwitcherComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismOrgSwitcherComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismOrgSwitcherComponent,
      selectors: [["prism-org-switcher"]],
      inputs: {
        smallScreen: "smallScreen"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismOrgSwitcherComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismOrgSwitcherComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$G,
  inputs: ['smallScreen']
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismOrgSwitcherComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismOrgSwitcherComponent, [{
    type: Component,
    args: [{
      selector: 'prism-org-switcher',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['smallScreen'],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismProfileImageComponent = class PrismProfileImageComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function PrismProfileImageComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismProfileImageComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismProfileImageComponent,
      selectors: [["prism-profile-image"]],
      inputs: {
        firstName: "firstName",
        imageSrc: "imageSrc",
        lastName: "lastName",
        size: "size"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismProfileImageComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismProfileImageComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$H,
  inputs: ['firstName', 'imageSrc', 'lastName', 'size']
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismProfileImageComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismProfileImageComponent, [{
    type: Component,
    args: [{
      selector: 'prism-profile-image',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['firstName', 'imageSrc', 'lastName', 'size'],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismProfileImageResizeComponent = class PrismProfileImageResizeComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['cropImageData']);
  }
  static {
    this.ɵfac = function PrismProfileImageResizeComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismProfileImageResizeComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismProfileImageResizeComponent,
      selectors: [["prism-profile-image-resize"]],
      inputs: {
        cancelButton: "cancelButton",
        cancelButtonDisabled: "cancelButtonDisabled",
        confirmButton: "confirmButton",
        confirmButtonDisabled: "confirmButtonDisabled",
        imageSrc: "imageSrc",
        visible: "visible"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismProfileImageResizeComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismProfileImageResizeComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$I,
  inputs: ['cancelButton', 'cancelButtonDisabled', 'confirmButton', 'confirmButtonDisabled', 'imageSrc', 'visible']
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismProfileImageResizeComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismProfileImageResizeComponent, [{
    type: Component,
    args: [{
      selector: 'prism-profile-image-resize',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['cancelButton', 'cancelButtonDisabled', 'confirmButton', 'confirmButtonDisabled', 'imageSrc', 'visible'],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismProgressDotsComponent = class PrismProgressDotsComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function PrismProgressDotsComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismProgressDotsComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismProgressDotsComponent,
      selectors: [["prism-progress-dots"]],
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismProgressDotsComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismProgressDotsComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$J
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismProgressDotsComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismProgressDotsComponent, [{
    type: Component,
    args: [{
      selector: 'prism-progress-dots',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: [],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismProgressIndicatorComponent = class PrismProgressIndicatorComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function PrismProgressIndicatorComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismProgressIndicatorComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismProgressIndicatorComponent,
      selectors: [["prism-progress-indicator"]],
      inputs: {
        mode: "mode",
        size: "size",
        type: "type",
        value: "value"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismProgressIndicatorComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismProgressIndicatorComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$K,
  inputs: ['mode', 'size', 'type', 'value']
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismProgressIndicatorComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismProgressIndicatorComponent, [{
    type: Component,
    args: [{
      selector: 'prism-progress-indicator',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['mode', 'size', 'type', 'value'],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismRadioButtonComponent = class PrismRadioButtonComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['radioButtonValueChange']);
  }
  static {
    this.ɵfac = function PrismRadioButtonComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismRadioButtonComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismRadioButtonComponent,
      selectors: [["prism-radio-button"]],
      inputs: {
        checked: "checked",
        disabled: "disabled",
        value: "value"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismRadioButtonComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismRadioButtonComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$L,
  inputs: ['checked', 'disabled', 'value']
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismRadioButtonComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismRadioButtonComponent, [{
    type: Component,
    args: [{
      selector: 'prism-radio-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['checked', 'disabled', 'value'],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismRadioGroupComponent = class PrismRadioGroupComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['valueChange']);
  }
  static {
    this.ɵfac = function PrismRadioGroupComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismRadioGroupComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismRadioGroupComponent,
      selectors: [["prism-radio-group"]],
      inputs: {
        disabled: "disabled",
        value: "value"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismRadioGroupComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismRadioGroupComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$M,
  inputs: ['disabled', 'value']
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismRadioGroupComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismRadioGroupComponent, [{
    type: Component,
    args: [{
      selector: 'prism-radio-group',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled', 'value'],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismRfMeterComponent = class PrismRfMeterComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function PrismRfMeterComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismRfMeterComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismRfMeterComponent,
      selectors: [["prism-rf-meter"]],
      inputs: {
        status: "status"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismRfMeterComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismRfMeterComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$N,
  inputs: ['status']
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismRfMeterComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismRfMeterComponent, [{
    type: Component,
    args: [{
      selector: 'prism-rf-meter',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['status'],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismScaleComponent = class PrismScaleComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function PrismScaleComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismScaleComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismScaleComponent,
      selectors: [["prism-scale"]],
      inputs: {
        max: "max",
        min: "min",
        orientation: "orientation",
        position: "position",
        step: "step",
        ticks: "ticks",
        type: "type"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismScaleComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismScaleComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$O,
  inputs: ['max', 'min', 'orientation', 'position', 'step', 'ticks', 'type']
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismScaleComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismScaleComponent, [{
    type: Component,
    args: [{
      selector: 'prism-scale',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['max', 'min', 'orientation', 'position', 'step', 'ticks', 'type'],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismSearchBarComponent = class PrismSearchBarComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['valueInput', 'valueChange']);
  }
  static {
    this.ɵfac = function PrismSearchBarComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismSearchBarComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismSearchBarComponent,
      selectors: [["prism-search-bar"]],
      inputs: {
        autocapitalize: "autocapitalize",
        autocomplete: "autocomplete",
        autocorrect: "autocorrect",
        autofocus: "autofocus",
        disabled: "disabled",
        inputmode: "inputmode",
        name: "name",
        placeholder: "placeholder",
        readonly: "readonly",
        required: "required",
        type: "type",
        value: "value"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismSearchBarComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismSearchBarComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$P,
  inputs: ['autocapitalize', 'autocomplete', 'autocorrect', 'autofocus', 'disabled', 'inputmode', 'name', 'placeholder', 'readonly', 'required', 'type', 'value']
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismSearchBarComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismSearchBarComponent, [{
    type: Component,
    args: [{
      selector: 'prism-search-bar',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['autocapitalize', 'autocomplete', 'autocorrect', 'autofocus', 'disabled', 'inputmode', 'name', 'placeholder', 'readonly', 'required', 'type', 'value'],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismSegmentedPickerComponent = class PrismSegmentedPickerComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['valueChange']);
  }
  static {
    this.ɵfac = function PrismSegmentedPickerComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismSegmentedPickerComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismSegmentedPickerComponent,
      selectors: [["prism-segmented-picker"]],
      inputs: {
        disabled: "disabled",
        name: "name",
        options: "options",
        value: "value"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismSegmentedPickerComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismSegmentedPickerComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$Q,
  inputs: ['disabled', 'name', 'options', 'value']
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismSegmentedPickerComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismSegmentedPickerComponent, [{
    type: Component,
    args: [{
      selector: 'prism-segmented-picker',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled', 'name', 'options', 'value'],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismSelectComponent = class PrismSelectComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['valueChange']);
  }
  static {
    this.ɵfac = function PrismSelectComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismSelectComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismSelectComponent,
      selectors: [["prism-select"]],
      inputs: {
        disabled: "disabled",
        editable: "editable",
        placement: "placement",
        value: "value",
        visible: "visible"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismSelectComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismSelectComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$R,
  inputs: ['disabled', 'editable', 'placement', 'value', 'visible']
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismSelectComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismSelectComponent, [{
    type: Component,
    args: [{
      selector: 'prism-select',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled', 'editable', 'placement', 'value', 'visible'],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismSidePanelComponent = class PrismSidePanelComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['close']);
  }
  static {
    this.ɵfac = function PrismSidePanelComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismSidePanelComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismSidePanelComponent,
      selectors: [["prism-side-panel"]],
      inputs: {
        side: "side"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismSidePanelComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismSidePanelComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$S,
  inputs: ['side']
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismSidePanelComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismSidePanelComponent, [{
    type: Component,
    args: [{
      selector: 'prism-side-panel',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['side'],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismSidePanelLayoutComponent = class PrismSidePanelLayoutComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function PrismSidePanelLayoutComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismSidePanelLayoutComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismSidePanelLayoutComponent,
      selectors: [["prism-side-panel-layout"]],
      inputs: {
        floatLeft: "floatLeft",
        floatRight: "floatRight",
        leftOpen: "leftOpen",
        rightOpen: "rightOpen"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismSidePanelLayoutComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismSidePanelLayoutComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$T,
  inputs: ['floatLeft', 'floatRight', 'leftOpen', 'rightOpen']
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismSidePanelLayoutComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismSidePanelLayoutComponent, [{
    type: Component,
    args: [{
      selector: 'prism-side-panel-layout',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['floatLeft', 'floatRight', 'leftOpen', 'rightOpen'],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismSliderComponent = class PrismSliderComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['valueInput', 'valueChange']);
  }
  static {
    this.ɵfac = function PrismSliderComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismSliderComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismSliderComponent,
      selectors: [["prism-slider"]],
      inputs: {
        disabled: "disabled",
        fader: "fader",
        max: "max",
        min: "min",
        step: "step",
        ticks: "ticks",
        value: "value",
        vertical: "vertical"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismSliderComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismSliderComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$U,
  inputs: ['disabled', 'fader', 'max', 'min', 'step', 'ticks', 'value', 'vertical']
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismSliderComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismSliderComponent, [{
    type: Component,
    args: [{
      selector: 'prism-slider',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled', 'fader', 'max', 'min', 'step', 'ticks', 'value', 'vertical'],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismSnackbarComponent = class PrismSnackbarComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['visibleChange']);
  }
  static {
    this.ɵfac = function PrismSnackbarComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismSnackbarComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismSnackbarComponent,
      selectors: [["prism-snackbar"]],
      inputs: {
        duration: "duration",
        visible: "visible"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismSnackbarComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismSnackbarComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$V,
  inputs: ['duration', 'visible']
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismSnackbarComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismSnackbarComponent, [{
    type: Component,
    args: [{
      selector: 'prism-snackbar',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['duration', 'visible'],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismStatusIndicatorComponent = class PrismStatusIndicatorComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function PrismStatusIndicatorComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismStatusIndicatorComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismStatusIndicatorComponent,
      selectors: [["prism-status-indicator"]],
      inputs: {
        shape: "shape",
        status: "status"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismStatusIndicatorComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismStatusIndicatorComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$W,
  inputs: ['shape', 'status']
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismStatusIndicatorComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismStatusIndicatorComponent, [{
    type: Component,
    args: [{
      selector: 'prism-status-indicator',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['shape', 'status'],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismStepComponent = class PrismStepComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['stepPropertyChange']);
  }
  static {
    this.ɵfac = function PrismStepComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismStepComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismStepComponent,
      selectors: [["prism-step"]],
      inputs: {
        active: "active",
        completed: "completed",
        disabled: "disabled",
        label: "label",
        loaded: "loaded"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismStepComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismStepComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$X,
  inputs: ['active', 'completed', 'disabled', 'label', 'loaded']
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismStepComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismStepComponent, [{
    type: Component,
    args: [{
      selector: 'prism-step',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['active', 'completed', 'disabled', 'label', 'loaded'],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismStepperComponent = class PrismStepperComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function PrismStepperComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismStepperComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismStepperComponent,
      selectors: [["prism-stepper"]],
      inputs: {
        lazy: "lazy",
        linear: "linear"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismStepperComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismStepperComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$Y,
  inputs: ['lazy', 'linear'],
  methods: ['next', 'previous', 'reset']
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismStepperComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismStepperComponent, [{
    type: Component,
    args: [{
      selector: 'prism-stepper',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['lazy', 'linear'],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismStepperDialogComponent = class PrismStepperDialogComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['visibleChange']);
  }
  static {
    this.ɵfac = function PrismStepperDialogComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismStepperDialogComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismStepperDialogComponent,
      selectors: [["prism-stepper-dialog"]],
      inputs: {
        visible: "visible"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismStepperDialogComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismStepperDialogComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$Z,
  inputs: ['visible']
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismStepperDialogComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismStepperDialogComponent, [{
    type: Component,
    args: [{
      selector: 'prism-stepper-dialog',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['visible'],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismSwitchComponent = class PrismSwitchComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['valueChange']);
  }
  static {
    this.ɵfac = function PrismSwitchComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismSwitchComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismSwitchComponent,
      selectors: [["prism-switch"]],
      inputs: {
        checked: "checked",
        disabled: "disabled",
        value: "value"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismSwitchComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismSwitchComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$_,
  inputs: ['checked', 'disabled', 'value']
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismSwitchComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismSwitchComponent, [{
    type: Component,
    args: [{
      selector: 'prism-switch',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['checked', 'disabled', 'value'],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismTabComponent = class PrismTabComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['selectTab', 'closeTab']);
  }
  static {
    this.ɵfac = function PrismTabComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismTabComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismTabComponent,
      selectors: [["prism-tab"]],
      inputs: {
        disabled: "disabled",
        link: "link",
        selected: "selected",
        status: "status",
        tabId: "tabId",
        tooltipDisabled: "tooltipDisabled",
        tooltipPlacement: "tooltipPlacement",
        tooltipText: "tooltipText",
        type: "type"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismTabComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismTabComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$$,
  inputs: ['disabled', 'link', 'selected', 'status', 'tabId', 'tooltipDisabled', 'tooltipPlacement', 'tooltipText', 'type']
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismTabComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismTabComponent, [{
    type: Component,
    args: [{
      selector: 'prism-tab',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled', 'link', 'selected', 'status', 'tabId', 'tooltipDisabled', 'tooltipPlacement', 'tooltipText', 'type'],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismTabGroupComponent = class PrismTabGroupComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function PrismTabGroupComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismTabGroupComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismTabGroupComponent,
      selectors: [["prism-tab-group"]],
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismTabGroupComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismTabGroupComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$10
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismTabGroupComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismTabGroupComponent, [{
    type: Component,
    args: [{
      selector: 'prism-tab-group',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: [],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismToolbarComponent = class PrismToolbarComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function PrismToolbarComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismToolbarComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismToolbarComponent,
      selectors: [["prism-toolbar"]],
      inputs: {
        showDividers: "showDividers"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismToolbarComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismToolbarComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$11,
  inputs: ['showDividers']
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismToolbarComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismToolbarComponent, [{
    type: Component,
    args: [{
      selector: 'prism-toolbar',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['showDividers'],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismTooltipComponent = class PrismTooltipComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function PrismTooltipComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismTooltipComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismTooltipComponent,
      selectors: [["prism-tooltip"]],
      inputs: {
        disabled: "disabled",
        placement: "placement",
        text: "text"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismTooltipComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismTooltipComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$12,
  inputs: ['disabled', 'placement', 'text']
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismTooltipComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismTooltipComponent, [{
    type: Component,
    args: [{
      selector: 'prism-tooltip',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled', 'placement', 'text'],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismTooltipTextComponent = class PrismTooltipTextComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function PrismTooltipTextComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismTooltipTextComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismTooltipTextComponent,
      selectors: [["prism-tooltip-text"]],
      inputs: {
        text: "text",
        visible: "visible"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismTooltipTextComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismTooltipTextComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$13,
  inputs: ['text', 'visible']
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismTooltipTextComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismTooltipTextComponent, [{
    type: Component,
    args: [{
      selector: 'prism-tooltip-text',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['text', 'visible'],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let PrismTransmitterInfoComponent = class PrismTransmitterInfoComponent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function PrismTransmitterInfoComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrismTransmitterInfoComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrismTransmitterInfoComponent,
      selectors: [["prism-transmitter-info"]],
      inputs: {
        transmitter: "transmitter"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PrismTransmitterInfoComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
PrismTransmitterInfoComponent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$14,
  inputs: ['transmitter']
}), __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])], PrismTransmitterInfoComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrismTransmitterInfoComponent, [{
    type: Component,
    args: [{
      selector: 'prism-transmitter-info',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['transmitter'],
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
class TextValueAccessor extends ValueAccessor {
  constructor(el) {
    super(el);
  }
  static {
    this.ɵfac = function TextValueAccessor_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TextValueAccessor)(i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TextValueAccessor,
      selectors: [["prism-input"], ["prism-menu"], ["prism-radio-group"], ["prism-search-bar"], ["prism-segmented-picker"], ["prism-select"], ["prism-input"]],
      hostBindings: function TextValueAccessor_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("valueChange", function TextValueAccessor_valueChange_HostBindingHandler($event) {
            return ctx.handleChangeEvent($event.target.value);
          })("valueInput", function TextValueAccessor_valueInput_HostBindingHandler($event) {
            return ctx.handleChangeEvent($event.target.value);
          });
        }
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([{
        provide: NG_VALUE_ACCESSOR,
        useExisting: TextValueAccessor,
        multi: true
      }]), i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TextValueAccessor, [{
    type: Directive,
    args: [{
      standalone: true,
      /* tslint:disable-next-line:directive-selector */
      selector: 'prism-input, prism-menu, prism-radio-group, prism-search-bar, prism-segmented-picker, prism-select, prism-input',
      host: {
        '(valueChange)': 'handleChangeEvent($event.target.value)',
        '(valueInput)': 'handleChangeEvent($event.target.value)'
      },
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: TextValueAccessor,
        multi: true
      }]
    }]
  }], () => [{
    type: i0.ElementRef
  }], null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { BooleanValueAccessor, NumericValueAccessor, PRISM_DIALOG_DATA, PRISM_DIALOG_DEFAULT_OPTIONS, PrismAccordionComponent, PrismAccordionPanelComponent, PrismBadgeComponent, PrismBatteryBarComponent, PrismBatteryComponent, PrismBatteryGaugeComponent, PrismBatteryIndicatorComponent, PrismBetaIndicatorComponent, PrismBreadcrumbComponent, PrismBreadcrumbItemComponent, PrismButtonComponent, PrismButtonGroupComponent, PrismCalloutComponent, PrismCardComponent, PrismCheckboxComponent, PrismChipComponent, PrismDialogComponent, PrismDialogConfig, PrismDialogRef, PrismDialogService, PrismFileInputComponent, PrismFloatingDialogBaseComponent, PrismFloatingDialogComponent, PrismGraduatedContainerComponent, PrismIconButtonComponent, PrismIconComponent, PrismInputComponent, PrismKnobComponent, PrismLabelValueComponent, PrismLayoutComponent, PrismLinkInfoComponent, PrismListComponent, PrismListItemComponent, PrismMenuBaseComponent, PrismMenuComponent, PrismMenuItemComponent, PrismMeterComponent, PrismMeterControllerComponent, PrismMuteButtonComponent, PrismNavButtonComponent, PrismNavComponent, PrismNavGroupComponent, PrismNavToggleButtonComponent, PrismNumberInputComponent, PrismOrgSwitcherComponent, PrismProfileImageComponent, PrismProfileImageResizeComponent, PrismProgressDotsComponent, PrismProgressIndicatorComponent, PrismRadioButtonComponent, PrismRadioGroupComponent, PrismRfMeterComponent, PrismScaleComponent, PrismSearchBarComponent, PrismSegmentedPickerComponent, PrismSelectComponent, PrismSidePanelComponent, PrismSidePanelLayoutComponent, PrismSliderComponent, PrismSnackbarComponent, PrismStatusIndicatorComponent, PrismStepComponent, PrismStepperComponent, PrismStepperDialogComponent, PrismSwitchComponent, PrismTabComponent, PrismTabGroupComponent, PrismToolbarComponent, PrismTooltipComponent, PrismTooltipTextComponent, PrismTransmitterInfoComponent, TextValueAccessor };
