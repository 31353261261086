<sh-app-access-not-configured
	*ngIf="(secondaryNavService.getIsPermissionAvailable$() | async) === false && !hasAddLicenseAccess()"
></sh-app-access-not-configured>
<ng-container *ngIf="(secondaryNavService.getIsPermissionAvailable$() | async) === true">
	<ng-container>
		<ng-container *ngIf="getSecondaryNavListItems$ | async as secondaryNavListItems">
			@if (secondaryNavListItems.length > 0) {
				<sh-secnav-layout *transloco="let t" [isCloseButtonEnabled]="false" class="sh-secondary-nav-home">
					<sh-secnav-layout-secnav>
						<span id="sh-secondary-nav-header">{{ t(routeTitle) }}</span>
						<mat-nav-list *ngFor="let secondaryNavListItem of getSecondaryNavListItems$ | async">
							<mat-list-item
								(click)="navigateToPath(secondaryNavListItem)"
								id="sh-secondary-nav-{{ secondaryNavListItem.id }}"
								class="sh-secondary-nav-list-item"
								[ngClass]="{
									'sh-secondary-nav-org-list-item':
										secondaryNavListItem.feature === 'organization-settings'
								}"
							>
								<div class="sh-secondary-nav-content">
									<prism-icon
										*ngIf="secondaryNavListItem.icon"
										[icon]="secondaryNavListItem.icon"
										class="sh-secondary-nav-menu-list-item-prism-icon"
										id="sh-secondary-nav-{{ secondaryNavListItem.id }}-icon"
										size="sm"
									></prism-icon>
									<span id="sh-secondary-nav-nav-menu-list-item-{{ secondaryNavListItem.id }}-span">{{
										secondaryNavListItem.listItem
									}}</span>
								</div>
							</mat-list-item>
						</mat-nav-list>
					</sh-secnav-layout-secnav>
					<sh-secnav-layout-content>
						<div id="sh-secondary-nav-content-div">
							<div id="sh-secondary-nav-right-content-div">
								<ng-container *ngIf="activeListItem$ | async as activeListItem">
									<sh-header-toolbar
										[showSidenavToggleButton]="true"
										[showBreadCrumbs]="false"
										[showFeatureButton]="!isSmallDevice() && !isLandscape()"
										[showNotifications]="true"
										(toggleSidenav)="openSidenav()"
										(signOut)="onSignOut()"
									></sh-header-toolbar>
								</ng-container>
								<sh-breadcrumbs
									*ngIf="breadCrumbs().length"
									[breadcrumbs]="breadCrumbs()"
									(breadcrumbClick)="breadCrumbRedirect($event)"
								></sh-breadcrumbs>
								<router-outlet></router-outlet>
							</div>
							<div id="sh-secondary-nav-footer-content-div">
								<sh-footer></sh-footer>
							</div>
						</div>
					</sh-secnav-layout-content>
				</sh-secnav-layout>
			}
		</ng-container>
		<ng-container>
			@if (hasAddLicenseAccess()) {
				<router-outlet></router-outlet>
			} @else {
				<div id="sh-secondary-nav-unavailable-container" *ngIf="!hasAddLicenseAccess()">
					<div id="sh-secondary-nav-unavailable-container-div" *transloco="let t">
						<img
							id="sh-secondary-nav-unavailable-img"
							[src]="listItemUnavailableContent().imageSrc | version | async"
						/>
						<div id="sh-secondary-nav-unavailable-sub-title-head-div">
							{{ listItemUnavailableContent().header }}
						</div>
						<div id="sh-secondary-nav-unavailable-sub-title-text-div">
							{{ listItemUnavailableContent().text }}
						</div>
						@if (listItemUnavailableContent().requestButton) {
							<button
								mat-button
								id="sh-secondary-nav-unavailable-button"
								(click)="openRequestFormDialog()"
							>
								{{ t('cloud.shure-cloud.license-management.request-business-account') }}
							</button>
						}
					</div>
				</div>
			}
		</ng-container>
	</ng-container>
</ng-container>
