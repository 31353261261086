<footer *transloco="let t">
	<div id="sh-footer-link-list-div">
		@if (!isSmallDevice()) {
			<div id="sh-footer-buttons-container">
				@for (footerLink of activeFooterLinksList; track footerLink) {
					<a
						class="sh-footer-link-list-a prism-body-1"
						id="sh-footer-{{ footerLink.id }}-a"
						(click)="onFooterLinkClick(footerLink)"
					>
						<span id="sh-footer-{{ footerLink.id }}-label">{{ footerLink.label() }}</span>
					</a>
				}
			</div>
		}
		<div id="sh-footer-copyright-div">{{ t('cloud.shared.footer.shure-rights', { toDate: currentYear }) }}</div>
		@if (isSmallDevice()) {
			<div id="sh-footer-menu-container">
				<prism-menu id="sh-footer-links-menu">
					<prism-icon
						id="sh-footer-links-selector"
						role="menuBtn"
						icon="more_vert"
						slot="menu-trigger"
					></prism-icon>
					@for (footerLink of activeFooterLinksList; track footerLink) {
						<prism-menu-item
							class="prism-caption"
							id="sh-footer-links-menu-item"
							role="menuitem"
							(click)="onFooterLinkClick(footerLink)"
							>{{ footerLink.label() }}</prism-menu-item
						>
					}
				</prism-menu>
			</div>
		}
	</div>
</footer>
