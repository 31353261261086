import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { of } from 'rxjs';
import { switchMap, tap, catchError } from 'rxjs/operators';

import { LayoutConfigItems } from '../_AUTOGENERATED_';
import { ApiUsersService } from '../_AUTOGENERATED_/services/api-users.service';

export interface UserAppsPreferencesCard {
	card: string;
	visibility: boolean;
}

export const DEFAULT_CARDS: UserAppsPreferencesCard[] = [
	{ card: 'homepage_learnmore', visibility: true },
	{ card: 'homepage_managelicenses', visibility: true },
	{ card: 'homepage_adddevices', visibility: true }
];

export interface UserAppsPreferencesState {
	activeCards: UserAppsPreferencesCard[];
	i18nKey?: string;
	i18nCategory?: 'error' | 'warning' | 'info';
}

@Injectable({
	providedIn: 'root'
})
export class TilesApplicationService extends ComponentStore<UserAppsPreferencesState> {
	public readonly activeCards = this.select((state) => state.activeCards);

	/** Load apps preferences by component id */
	public loadTiles = this.effect<string>((source$) =>
		source$.pipe(
			switchMap((componentId) =>
				this.apiUsersService.getUserApplicationPreferences$Response({ componentIds: componentId }).pipe(
					tap((response) => {
						const preferencesResponse = response?.body?.body?.[0];
						const configuration = preferencesResponse?.configuration;

						const activeCards = this.hasCardsConfig(configuration)
							? configuration.cards ?? DEFAULT_CARDS
							: DEFAULT_CARDS;
						this.patchState({ activeCards });
					}),
					catchError((error) => {
						const i18nKey = error?.error?.i18nKey;
						this.patchState({ i18nKey, i18nCategory: 'error' });
						return of();
					})
				)
			)
		)
	);

	/** Reset apps preferences for component id to default preferences */
	public resetTiles = this.effect<string>((source$) =>
		source$.pipe(
			switchMap((componentId) =>
				this.apiUsersService
					.updateUserApplicationPreferences$Response({
						body: { componentId, configuration: { cards: DEFAULT_CARDS } }
					})
					.pipe(
						tap((response) => {
							const i18nKey = response.body.body?.i18nKey;
							this.patchState({ activeCards: DEFAULT_CARDS, i18nKey, i18nCategory: 'info' });
						}),
						catchError((error) => {
							const i18nKey = error?.error?.i18nKey;
							this.patchState({ i18nKey, i18nCategory: 'error' });
							return of(null);
						})
					)
			)
		)
	);

	constructor(private readonly apiUsersService: ApiUsersService) {
		super({ activeCards: DEFAULT_CARDS });
	}
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public hasCardsConfig(config: any): config is { cards?: LayoutConfigItems } {
		return config && typeof config === 'object' && 'cards' in config;
	}
}
