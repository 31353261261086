/* eslint-disable dot-notation */
import { CommonModule } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	ElementRef,
	Inject,
	OnDestroy,
	OnInit,
	Renderer2,
	signal
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { map, Observable, Subject, tap } from 'rxjs';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { VerificationRequestDto } from '@shure/cloud/shared/request-business-account/data-access';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { RequestBusinessAccountService } from '@shure/cloud/shared/request-business-account/feature-request-business-account';
import { BreakpointService } from '@shure/cloud/shared/services/media-breakpoints';
import {
	OrganizationsStoreService,
	VerificationStatus
} from '@shure/cloud/shared/services/organizations-store-service';
import { PipesModule } from '@shure/cloud/shared/ui/pipes';
import { AnalyticsEvents, AnalyticsService } from '@shure/cloud/shared/utils/analytics';
import { APP_ENVIRONMENT, AppEnvironment } from '@shure/cloud/shared/utils/config';
import { PrismIconComponent } from '@shure/prism-angular-components';

import { AppAccessNotConfiguredComponent } from '../app-access-not-configured';
import { BreadcrumbsModule, IBreadcrumb, IBreadcrumbClickEvent } from '../breadcrumbs';
import { FooterModule } from '../footer';
import { HeaderToolbarModule, HeaderToolbarService } from '../header-toolbar';
import { SnackbarService, CloseTextOption } from '../html-snackbar';
import {
	SecnavLayoutStateFacade,
	SecondaryNavLayoutModule,
	SidenavLayoutStateFacade,
	StackedLayoutModule
} from '../legacy-layout';

import { SecondaryNavList, SecondaryNavService, ToggleSecondayNavList } from './secondary-nav.service';

@Component({
	selector: 'sh-secondary-nav',
	styleUrls: ['./secondary-nav.component.scss'],
	templateUrl: 'secondary-nav.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		CommonModule,
		MatButtonModule,
		MatListModule,
		MatDividerModule,
		MatIconModule,
		RouterModule,
		TranslocoModule,
		FlexLayoutModule,
		StackedLayoutModule,
		PipesModule,
		HeaderToolbarModule,
		FooterModule,
		SecondaryNavLayoutModule,
		BreadcrumbsModule,
		AppAccessNotConfiguredComponent,
		PrismIconComponent
	]
})
export class SecondaryNavComponent implements OnInit, OnDestroy, AfterViewInit {
	public isOpen$: Observable<boolean> | undefined;
	private destroy$: Subject<void> = new Subject<void>();
	public readonly isSmallDevice = this.breakPointService.isLteXSmall;
	public readonly isLandscape = this.breakPointService.isHandsetLandscape;
	public getSecondaryNavListItems$!: Observable<SecondaryNavList[]>;
	private secondaryNavList!: SecondaryNavList[];
	public breadCrumb!: IBreadcrumb[];
	public toggleSecondaryNavList = ToggleSecondayNavList;
	public readonly breadCrumbs = toSignal(this.headerToolbarService.getBreadCrumb$(), {
		initialValue: []
	});
	public activeListItem$!: Observable<string>;
	public listItemUnavailableContent = signal({
		imageSrc: '',
		header: '',
		text: '',
		requestButton: false
	});

	public routeTitle = '';
	public hasAddLicenseAccess = signal(false);
	constructor(
		private layoutStateFacade: SecnavLayoutStateFacade,
		private sideNavStateFacade: SidenavLayoutStateFacade,
		private router: Router,
		private route: ActivatedRoute,
		public secondaryNavService: SecondaryNavService,
		private breakPointService: BreakpointService,
		private headerToolbarService: HeaderToolbarService,
		private analyticsService: AnalyticsService,
		private translocoService: TranslocoService,
		private elementRef: ElementRef,
		private readonly renderer: Renderer2,
		private organizationsStore: OrganizationsStoreService,
		@Inject(APP_ENVIRONMENT) public appEnv: AppEnvironment,
		private requestBusinessService: RequestBusinessAccountService,
		private readonly destroyRef: DestroyRef,
		private snackBarService: SnackbarService
	) {}

	public ngOnInit(): void {
		this.routeTitle = this.route.snapshot.data['title'];
		const code = this.route.snapshot.queryParams['code'];

		if (this.router.url.includes('licenses/claim') && (code || code !== undefined)) {
			this.navigateToLicenseClaim(code);
			return;
		}
		this.getSecondaryNavListItems$ = this.secondaryNavService.getSecondaryNavListItems$().pipe(
			// Sorting the nav list items to be in asc order.
			map((secondaryNavList) => {
				if (this.route.snapshot.data?.['sortNavList'] === false) {
					return secondaryNavList;
				}
				return secondaryNavList.sort((a, b) => a.listItem.localeCompare(b.listItem));
			}),
			tap((secondaryNavList) => {
				if (secondaryNavList.length > 0) {
					this.secondaryNavList = secondaryNavList.map((navItem) => {
						if (navItem.translationKey) {
							const translatedValue = this.translocoService.translate(navItem.translationKey);
							navItem.listItem = translatedValue;
							navItem.title = translatedValue;
						}
						return navItem;
					});
					this.activeListItem$ = this.secondaryNavService.getActiveListItem$();

					this.headerToolbarService.setHeader(secondaryNavList[0].title ?? '');

					this.isOpen$ = this.layoutStateFacade.isOpen$;
					if (!history.state.row && !secondaryNavList.some((c) => this.router.url.includes(c.key))) {
						this.secondaryNavService.setActiveListItem(secondaryNavList[0].key);
						const secondaryNavPath =
							secondaryNavList[0].keyInParams === false
								? `${secondaryNavList[0].feature}/${secondaryNavList[0].path}`
								: `${secondaryNavList[0].feature}/${secondaryNavList[0].key}/${secondaryNavList[0].path}`;
						this.router.navigate([secondaryNavPath]);
					} else {
						secondaryNavList.map((navList) => {
							if (this.router.url.includes(navList.key)) {
								this.secondaryNavService.setActiveListItem(navList.key);
								this.headerToolbarService.setHeader(navList.title ?? '');
							}
						});
					}
				} else {
					if (this.router.url.includes('license')) {
						this.secondaryNavService.setToggleSecondaryNav(ToggleSecondayNavList.Licenses);
						const imageSrc = 'assets/images/no-license-available.svg';
						const header = this.translocoService.translate(
							'cloud.shure-cloud.license-management.no-licenses-to-display'
						);
						let text = '';
						let requestButton = false;
						const accountInfo = this.organizationsStore.accountInformation();
						const verificationStatus = accountInfo.organizationDetails?.verificationStatus;
						const hasAccountNumber = !!accountInfo.organizationDetails?.accountNumber;
						const convertToBusinessMessage = this.translocoService.translate(
							'cloud.shure-cloud.license-management.convert-to-business-account'
						);
						const licenseShowAfterPurchaseMessage = this.translocoService.translate(
							'cloud.shure-cloud.license-management.licenses-show-up-after-purchase'
						);
						if (this.appEnv.featureFlags?.verifiedOrganizationLicenses !== false) {
							if (
								!accountInfo.organizationDetails?.accountNumber ||
								this.organizationsStore.testPermission('licenses/claim-through-code') ||
								accountInfo?.organizationDetails?.verificationStatus !== VerificationStatus.VERIFIED
							) {
								this.navigateToLicenseClaim(code);
							} else if (
								verificationStatus === VerificationStatus.NONVERIFIED &&
								this.secondaryNavService.getRequestBusinessPermission()
							) {
								text = convertToBusinessMessage;
								requestButton = true;
							} else {
								text = licenseShowAfterPurchaseMessage;
							}
						} else {
							text = hasAccountNumber ? licenseShowAfterPurchaseMessage : convertToBusinessMessage;
						}
						this.listItemUnavailableContent.update((currentContent) => ({
							...currentContent,
							header,
							imageSrc,
							text,
							requestButton
						}));
						this.headerToolbarService.setBreadCrumb([]);
					}
				}
			})
		);
	}

	public ngAfterViewInit(): void {
		if (this.secondaryNavList) {
			if (this.secondaryNavList.some((c) => this.router.url.includes(c.key))) {
				this.secondaryNavList.map((navList) => {
					if (this.router.url.includes(navList.key)) {
						this.toggleActiveProductType(navList);
					}
				});
			} else {
				this.toggleActiveProductType(this.secondaryNavList[0]);
			}
		} else {
			this.headerToolbarService.setBreadCrumb([]);
		}
	}

	public navigateToPath(event: SecondaryNavList): void {
		this.secondaryNavService.setActiveListItem(event.key);

		const updatedNavList = this.secondaryNavList.filter((navList) => event.listItem !== navList.listItem);

		this.toggleActiveProductType(event, updatedNavList);
		const secondaryNavPath =
			event.keyInParams === false
				? `${event.feature}/${event.path}`
				: `${event.feature}/${event.key}/${event.path}`;
		this.router.navigate([secondaryNavPath]);
		this.headerToolbarService.setHeader(event.title ?? '');
	}

	public navigateToLicenseClaim(code: string): void {
		this.hasAddLicenseAccess.set(true);
		this.secondaryNavService.setIsPermissionAvailable(true);
		this.secondaryNavService.setSecondaryNavListItems([]);
		this.headerToolbarService.setHeader('');
		if (code || code !== undefined) {
			this.router.navigate(['licenses/claim'], {
				queryParams: { code: code },
				queryParamsHandling: 'merge',
				replaceUrl: true
			});
		} else {
			this.router.navigate(['licenses/claim']);
		}
	}

	/**
	 * Handle Breadcrumb click event and redirect to page
	 * @param breadCrumb - Breadcrumb which is clicked
	 */
	public breadCrumbRedirect(breadCrumb: IBreadcrumbClickEvent): void {
		this.router.navigate([`/${breadCrumb.id}`]);
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
		this.secondaryNavService.resetUpdatedSecondaryNavListItems();
		this.headerToolbarService.setHeader('');
	}
	public openSidenav(): void {
		this.sideNavStateFacade.open();
	}

	/**
	 * Opens a dialog for requesting a business account verification.
	 */
	public openRequestFormDialog(): void {
		this.requestBusinessService
			.openRequestFormDialog()
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((verificationRequestData: VerificationRequestDto) => {
				if (verificationRequestData) {
					this.submitVerificationRequest(verificationRequestData);
				}
			});
	}

	/**
	 * Submits a verification request for the organization.
	 * @param verificationRequestData - The data required for the verification request.
	 */
	public submitVerificationRequest(verificationRequestData: VerificationRequestDto): void {
		this.requestBusinessService
			.submitVerificationRequest(verificationRequestData)
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe({
				next: (verificationResponse) => {
					this.listItemUnavailableContent.update((currentContent) => ({
						...currentContent,
						text: this.translocoService.translate(
							'cloud.shure-cloud.license-management.licenses-show-up-after-purchase'
						),
						requestButton: false
					}));

					this.snackBarService.open(
						this.translocoService.translate(
							'cloud.shure-cloud.request-business-account.' + verificationResponse.body.body?.i18nKey
						),
						CloseTextOption.Ok
					);
				},
				error: (error) => {
					const errorMessage = error.error;

					this.snackBarService.open(
						this.translocoService.translate(
							errorMessage?.i18nKey
								? 'cloud.shared.error-labels.' + errorMessage.i18nKey
								: errorMessage.message
						),
						CloseTextOption.Ok
					);
				}
			});
	}

	/**
	 * function called when user signs out
	 */
	public onSignOut(): void {
		this.analyticsService.analyticDataCollector(AnalyticsEvents.SignOut, {});
	}

	/**
	 * This method will toggle background color for active list item based on unique id.
	 * @param event - navigation list selected
	 * @param updatedNavList - updated list which excludes inactive list items
	 */
	private toggleActiveProductType(event: SecondaryNavList, updatedNavList?: SecondaryNavList[]): void {
		const navListElementRef = this.elementRef.nativeElement.querySelector(`#sh-secondary-nav-${event.id}`);
		this.renderer.setStyle(navListElementRef, 'background-color', 'var(--prism-color-theme-surface-variant)');
		updatedNavList?.forEach((navList) => {
			const navListElementRef = this.elementRef.nativeElement.querySelector(`#sh-secondary-nav-${navList.id}`);
			this.renderer.removeStyle(navListElementRef, 'background-color');
		});
	}
}
