import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { IBreadcrumb, IBreadcrumbClickEvent } from './breadcrumbs.models';

/**
 * ```<sh-breadcrumbs>``` are a list-like component used to show the path taken when
 * displaying nested structures like folders.
 */
@Component({
	selector: 'sh-breadcrumbs',
	templateUrl: './breadcrumbs.component.html',
	styleUrls: ['./breadcrumbs.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreadcrumbsComponent {
	/**
	 * List of breadcrumbs to show
	 */
	@Input()
	public breadcrumbs: IBreadcrumb[] = [];

	/**
	 * Disable click on last breadcrumb.
	 */
	@Input()
	public disableLast = true;

	/**
	 * Event emitted when a breadcrumb is clicked.
	 * ({id: string})
	 */
	@Output()
	public breadcrumbClick: EventEmitter<IBreadcrumbClickEvent> = new EventEmitter<IBreadcrumbClickEvent>();

	/**
	 * @ignore internal
	 */
	public onBreadcrumbClick(crumb: IBreadcrumb): void {
		this.breadcrumbClick.emit({ id: crumb.id });
	}

	/**
	 * @ignore internal
	 */
	public trackByFn(_index: number, crumb: IBreadcrumb): string {
		return crumb.id;
	}

	/**
	 * Handles breadcrumb click, ignoring the last one if `disableLast` is true.
	 * @param {IBreadcrumb} crumb - The clicked breadcrumb.
	 */
	public onCrumbClick(crumb: IBreadcrumb): void {
		const isLastCrumb = this.disableLast && this.breadcrumbs.indexOf(crumb) === this.breadcrumbs.length - 1;
		if (!isLastCrumb) {
			this.onBreadcrumbClick(crumb);
		}
	}
}
