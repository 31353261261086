import { proxyCustomElement, HTMLElement, h, Host } from '@stencil/core/internal/client';
const prismGraduatedContainerCss = ":host{display:flex;--_padding:var(--prism-graduated-container-padding, 0px)}:host([orientation=horizontal]){flex-direction:column;--prism-slider-padding-inline:var(--_padding)}:host([orientation=vertical]){--prism-slider-padding-block:var(--_padding)}.pwc-scale-wrapper.has-slider.vertical{padding-block:var(--_padding)}.pwc-scale-wrapper.has-slider.horizontal{padding-inline:var(--_padding)}";
const PrismGraduatedContainerStyle0 = prismGraduatedContainerCss;
const PrismGraduatedContainer$1 = /*@__PURE__*/proxyCustomElement(class PrismGraduatedContainer extends HTMLElement {
  constructor() {
    super();
    this.__registerHost();
    this.__attachShadow();
    this.ticks = [];
    this.orientation = 'vertical';
  }
  render() {
    return h(Host, {
      key: 'fc11599f77b2109d5619255bb1f80b7e55684833'
    }, h("div", {
      key: '2301a3179478eb648598d71c1115f23b366e4c37',
      class: `pwc-scale-wrapper ${this.orientation}`,
      ref: el => this.wrapperBefore = el
    }, h("slot", {
      key: '85530e8e49db949e14988cf76a80ce1cef26a999',
      name: "scale-before",
      onSlotchange: e => this.scaleSlotChange(e, true)
    })), h("slot", {
      key: 'd66d11a4a9cd9154a10555c1e1b7d299e8fba133',
      onSlotchange: e => this.mainSlotChange(e)
    }), h("div", {
      key: '362cbdd5ddde91a37fd88d987530fd1013e8ef4f',
      class: `pwc-scale-wrapper after ${this.orientation}`,
      ref: el => this.wrapperAfter = el
    }, h("slot", {
      key: '6bb01e5c0b4d74839d3f770ac80cf038dd5c5098',
      name: "scale-after",
      onSlotchange: e => this.scaleSlotChange(e, false)
    })));
  }
  scaleSlotChange(event, before) {
    if (event.target.assignedElements()[0].tagName === 'PRISM-SCALE') {
      const scale = event.target.assignedElements()[0];
      scale.orientation = this.orientation;
      this.ticks = scale.ticks;
      if (before) {
        scale.position = this.orientation === 'vertical' ? 'left' : 'top';
      } else {
        scale.position = this.orientation === 'vertical' ? 'right' : 'bottom';
      }
    }
  }
  mainSlotChange(event) {
    const slottedElement = event.target.assignedElements()[0];
    if (slottedElement && slottedElement.tagName === 'PRISM-SLIDER') {
      this.sliderElement = slottedElement;
      this.wrapperBefore.classList.add('has-slider');
      this.wrapperAfter.classList.add('has-slider');
      if (this.ticks) {
        this.sliderElement.ticks = this.ticks;
      }
    }
  }
  static get style() {
    return PrismGraduatedContainerStyle0;
  }
}, [1, "prism-graduated-container", {
  "orientation": [513]
}]);
function defineCustomElement$1() {
  if (typeof customElements === "undefined") {
    return;
  }
  const components = ["prism-graduated-container"];
  components.forEach(tagName => {
    switch (tagName) {
      case "prism-graduated-container":
        if (!customElements.get(tagName)) {
          customElements.define(tagName, PrismGraduatedContainer$1);
        }
        break;
    }
  });
}
const PrismGraduatedContainer = PrismGraduatedContainer$1;
const defineCustomElement = defineCustomElement$1;
export { PrismGraduatedContainer, defineCustomElement };

